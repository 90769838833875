import React from 'react';
import * as buildEnvs from '../../../_envs.json';

// MockData
import { mockRefinance, mockCalculator, mockComparison } from '../../components/forms/MortgageContainer/data';

export const zeroIfNegativeOrEmpty = (input) =>
{
    const stringInput = `${input}`;
    return parseFloat(stringInput === '' || input < 0 ? 0 : stringInput.replace(/[^0-9\.]/g,''));
}
export const defaultApiUrl =
    'https://qa.lowermybills.com/api/calc/lendingtools';

export const siteApiUrl = () => 
{
    const activeEnv =
        process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'local';
    const url =
        process.env.WPAPI_URL ||
        (buildEnvs[activeEnv] ? buildEnvs[activeEnv].WPAPI_URL : defaultApiUrl);

    const storybook = (!process.env.STORYBOOK || process.env.STORYBOOK === 'false') ? false : process.env.STORYBOOK;

    return { url, activeEnv, storybook };
};

export const refinanceMortgage = async (dataForm, values, resultsFields) => 
{
    let response = mockRefinance;
    const { url, activeEnv, storybook } = siteApiUrl();

    if (
        activeEnv === 'qa' ||
        activeEnv === 'production' && !storybook
    ) 
    {
        const res = await fetch(
            `${url}/${dataForm.endpoint}?${new URLSearchParams(values)}`
        );
        
        if (res.status >= 400) 
        {
            throw Error(res.statusText);
        }

        response = await res.json();
    }

    return React.createElement(
        dataForm.resultComponent, 
        {
            n30Yr: response.calcInfoMap['30Yr'], 
            n15Yr: response.calcInfoMap['15Yr'], 
            n5YrARM: response.calcInfoMap['5YrARM'], 
            CashOut: response.calcInfoMap.CashOut,
            moreInfoRow: resultsFields,
            title: dataForm.tableTitle,
            disclosure:dataForm.disclosure,
        }
    
    );
};

export const mortgageCalculator = async (dataForm, values, resultsFields) => 
{
    let response = mockCalculator;
    const { url, activeEnv, storybook } = siteApiUrl();

    if (
        activeEnv === 'qa' ||
        activeEnv === 'production' && !storybook
    ) 
    {
        const res = await fetch(
            `${url}/${dataForm.endpoint}?${new URLSearchParams(values)}`
        );

        if (res.status >= 400) 
        {
            throw Error(res.statusText);
        }

        response = await res.json();
    }

    return React.createElement(
        dataForm.resultComponent,
        {
            n30Yr: response.calcInfoMap['30Yr'], 
            n15Yr: response.calcInfoMap['15Yr'], 
            n5YrARM: response.calcInfoMap['5YrARM'], 
            CashOut: response.calcInfoMap.CashOut,
            moreInfoRow: resultsFields,
            disclosure:dataForm.disclosure,
            title: dataForm.tableTitle,
        }
    );
};

export const debtToIncomeMortgage = (dataForm, values) => 
{
    const income = zeroIfNegativeOrEmpty(values.income);
    const housingPayments = zeroIfNegativeOrEmpty(values['housing-payments']);
    const creditPayments = zeroIfNegativeOrEmpty(values['credit-payments']);
    const loanPayments = zeroIfNegativeOrEmpty(values['loan-payments']);

    const response =
        income === 0
            ? 0
            : (
                  (housingPayments + creditPayments + loanPayments) /
                  income
              ).toFixed(2);

    return React.createElement(
        dataForm.resultComponent, 
        { value: response, title: dataForm.tableTitle, disclosure:dataForm.disclosure,} 
    );
};

export const mortgageComparison = async (dataForm, values, resultsFields) => 
{
    const { storybook } = siteApiUrl();
    const loandTypesDataNodes = resultsFields?.nodes || []
    return React.createElement(
        dataForm.resultComponent,
        {
            userFormData: values,
            data: !storybook ? {} : mockComparison,
            title: dataForm.tableTitle,
            disclosure:dataForm.disclosure,
            loandTypesDataNodes
        }
    )
};
