// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    MORTGAGE_TABLE_CONTAINER: 'mortgage_table_container',
    MORTGAGE_TABLE_COLUMN_TITLES: 'mortgage_column_titles',
    MORTGAGE_TABLE_CONTENT: 'mortgage_table_content',
    MORTGAGE_CHECKMARK: 'mortgage_checkmark',
    MORTGAGE_CLOSE_ICON: 'mortgage_close_icon',
    MORTGAGE_TABLE_TITLE_CONTAINER: 'mortgage_table_title_container',
};

export const defaultValues = {
    MORTGAGE_TABLE_TITLE: 'Results:',
};

export const defaultAttributes = {
    "nodes": [
        {
            "title": "VA Loan",
            "content": "<ul>\n<li>Served 90 consecutive days of active service during wartime</li>\n<li>Served 181 days of active service during peacetime</li>\n<li>Have more than 6 years of service in the National Guard or Reserves</li>\n<li>You are the spouse of a service member who has died in the line of duty</li>\n<li>Must maintain a certain amount of income left over each month</li>\n<li>Satisfactory credit of around 600</li>\n</ul>\n",
            "cdmfields": {
                "checked": "No",
                "disclaimer": "Yes",
                "getCash": "No",
                "lowerPayment": "Yes",
                "lowerRate": "No",
                "payLess": "No",
                "payOff": "No",
                "shortTitle": "VA",
                "sort": 5
            }
        },
        {
            "title": "FHA Loan",
            "content": "<ul>\n<li>Steady employment over the past 2 years</li>\n<li>Valid SSN</li>\n<li>Must be for primary residence</li>\n<li>Loan must be from an approved FHA lender</li>\n<li>Have to pay mortgage insurance premiums</li>\n<li>Allows lower credit, minimum of 500</li>\n<li>Low down payment with a minimum of 3.5%</li>\n</ul>\n",
            "cdmfields": {
                "checked": "No",
                "disclaimer": "Yes",
                "getCash": "No",
                "lowerPayment": "Yes",
                "lowerRate": "No",
                "payLess": "No",
                "payOff": "No",
                "shortTitle": "FHA",
                "sort": 6
            }
        },
        {
            "title": "5/1 Year Adjustable",
            "content": "<ul>\n<li>Excellent credit, likely needs to be 700+</li>\n<li>At least 5% down payment</li>\n<li>Steady income</li>\n</ul>\n",
            "cdmfields": {
                "checked": "No",
                "disclaimer": "No",
                "getCash": "No",
                "lowerPayment": "Yes",
                "lowerRate": "Yes",
                "payLess": "No",
                "payOff": "No",
                "shortTitle": "5/1 ARM",
                "sort": 3
            }
        },
        {
            "title": "Cash Out Refinance",
            "content": "<ul>\n<li>Must have owned current home for at least a year</li>\n<li>Must have a minimum credit score-usually higher than a normal/previous refinance requirement</li>\n<li>Current loan to value ratio must be around 85%</li>\n</ul>\n",
            "cdmfields": {
                "checked": "No",
                "disclaimer": "No",
                "getCash": "Yes",
                "lowerPayment": "Depends On Term",
                "lowerRate": "Depends On Term",
                "payLess": "Depends On Term",
                "payOff": "Depends On Term",
                "shortTitle": "Cash Out",
                "sort": 4
            }
        },
        {
            "title": "15 Year Fixed",
            "content": "<ul>\n<li>Low to no debt</li>\n<li>Excellent credit, likely needs to be 700+</li>\n<li>Steady income</li>\n</ul>\n",
            "cdmfields": {
                "checked": "No",
                "disclaimer": "No",
                "getCash": "No",
                "lowerPayment": "No",
                "lowerRate": "Yes",
                "payLess": "Yes",
                "payOff": "Yes",
                "shortTitle": "15 Year Fixed",
                "sort": 2
            }
        },
        {
            "title": "30 Year Fixed",
            "content": "<ul>\n<li>Good to excellent credit, likely needs to be 600+</li>\n<li>Steady income</li>\n<li>At least 3% down payment</li>\n</ul>\n",
            "cdmfields": {
                "checked": "No",
                "disclaimer": "No",
                "getCash": "No",
                "lowerPayment": "Yes",
                "lowerRate": "No",
                "payLess": "No",
                "payOff": "No",
                "shortTitle": "30 Year Fixed",
                "sort": 1
            }
        }
    ]
};

export const defaultUserData = {
    mortgageCheckbox: [
        "mortgage-comparison-30-year-fixed",
        "mortgage-comparison-15-year-fixed",
        "mortgage-comparison-cash-out",
        "mortgage-comparison-5-1-arm",
        "mortgage-comparison-fha",
        "mortgage-comparison-va"  
    ]
};

export const tableVerticalTitles = [
    {
        title: 'Loan Type',
        id: 'loanType',
    },
    {
        title: 'Get Cash',
        id: 'getCash',
    },
    {
        title: 'Lower Payment',
        id: 'lowerPayment',
    },
    {
        title: 'Lower Rate',
        id: 'lowerRate',
    },
    {
        title: 'Pay Off Faster',
        id: 'payOff',
    },
    {
        title: 'Pay Less Interest',
        id: 'payLess',
    }
];