/**
 * replacements for html-react-parser
 * documentation here: https://github.com/remarkablemark/html-react-parser#readme
 * array of functions, each should take arg domNode
 * {name, attribs, type, parent, children, prev, next, startIndex, endIndex}
 * and return a React element to replace the domNode, or null/falsy to use default replacement
 * Stops replacing after a truthy return value; earlier rules take precedence.
 */

import toCalloutBox from './toCalloutBox';
import toLinker from './toLinker';
import toMortgageToolResults from './toMortgageToolResults';
import toEmbed from './toEmbed';

const parserReplacements = [
    toCalloutBox,
    toLinker,
    toMortgageToolResults,
    toEmbed,
];

export default parserReplacements;
