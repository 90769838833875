// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var container = "n_R";
export var headerContent = "n_Y";
export var headerLogo = "n_Z";
export var headerMenu = "n_0";
export var contentMenu = "n_1";
export var content = "n_h";