import React from 'react';
import PropTypes from 'prop-types';

import { iconInfo, iconNotice, iconDefinition, iconSnippet } from './icons';
import parseHTML from '../../../utilities/parseHTML';
import { testAttributes } from './Constants';

import {
    boxContainer,
    boxInfo,
    boxNotice,
    boxDefinition,
    boxSnippet,
    boxIcon,
} from './CalloutBox.module.scss';

export const iconbByType = {
    info: {
        addClass: boxInfo,
        icon: iconInfo,
    },
    notice: {
        addClass: boxNotice,
        icon: iconNotice,
    },
    definition: {
        addClass: boxDefinition,
        icon: iconDefinition,
    },
    snippet: {
        addClass: boxSnippet,
        icon: iconSnippet,
    },
};

export const fnValidateContent = (content) =>
    typeof content === 'string' ? parseHTML(content) : content;

export const fnValidateBoxType = (boxType) =>
    iconbByType[boxType]
        ? iconbByType[boxType]
        : {
              addClass: '',
              icon: '',
          };

const CalloutBox = ({ boxType, content, title, icon }) => 
{
    let boxAttribute = fnValidateBoxType(boxType);
    if ( icon !== null ) boxAttribute = {
        ...boxAttribute,
        icon
    }
    const boxContent = fnValidateContent(content);

    return (
        <div
            className={`${boxContainer} ${boxAttribute.addClass}`}
            data-test={testAttributes.CALLOUTBOX_MAIN_CONTAINER}
        >
            {title && <h2>{title}</h2>}
            {boxContent}
            { (boxAttribute.icon !== false)? <img alt="boxIcon" src={boxAttribute.icon} className={boxIcon} /> : <span /> }
        </div>
    );
};

CalloutBox.propTypes = {
    /** type of box, e.g. 'notice', 'info', 'snippet', 'notice' */
    boxType: PropTypes.oneOf(Object.keys(iconbByType)).isRequired,
    /** content of boxed, any mixed JSX */
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
        .isRequired,
    /** optional title appearing in box */
    title: PropTypes.string,
    /** If default/null, then default icon for boxType is used. Can be provided as string, or false == no icon */
    icon: PropTypes.oneOfType([
        PropTypes.string, PropTypes.oneOf([null, false])
    ])
};

CalloutBox.defaultProps = {
    title: '',
    icon: null
};

export default CalloutBox;
