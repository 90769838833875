import React from "react";
import PropTypes from 'prop-types';
import { errorContainer, errorTextMessage} from './FormError.module.scss';
import { testAttributes } from './Constants';

const FormError = ({errorMessage}) => (
    <div data-test={testAttributes.MORTGAGE_FORM_ERROR} className={errorContainer}>
        <span className={errorTextMessage}>{errorMessage}</span>
    </div>
);

FormError.propTypes = {
    /** FormError errorMessage */
    errorMessage: PropTypes.string.isRequired,
}

export default FormError;