import { createElement, Fragment } from 'react';
import SampleEmbed from './SampleEmbed/SampleEmbed';
import sanitizeHTML from '../../../../purify';

export const allowList = {
    sample: SampleEmbed,
};

const embeds = (props) => 
{
    if (!Object.keys(allowList).includes(props['data-key']))
        return createElement(Fragment);
    const sanitizedProps = Object.keys(props).reduce(
        (ob, key) => ({
            ...ob,
            [key]: sanitizeHTML(props[key]),
        }),
        {}
    );
    return createElement(allowList[props['data-key']], sanitizedProps);
};
export default embeds;
