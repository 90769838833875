import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes } from './Constants';
import { indicator, step, steps, present, finish, icon, line } from './StepIndicator.module.scss';

const Indicator = ({ data, current }) => (
    <div className={indicator} data-test={testAttributes.MORTGAGE_STEP_CONTAINER}>
        {data.map((item) =>
            (
                <div key={`step-${item.step}`} className={steps}  >
                    <div className={step} data-test={testAttributes.MORTGAGE_STEP_INDICATOR}>
                        <div
                            className={`${icon} ${current > item.step ? finish : ""} ${current === item.step ? present : ""}`}
                            data-test={testAttributes.MORTGAGE_STEP_ICON} 
                        >
                            {item.step}
                        </div>
                    </div>
                    {data.length !== item.step &&
                        <div 
                            className={`${line} ${current > item.step ? present : ""}`}
                            data-test={testAttributes.MORTGAGE_STEP_LINE}
                        />
                    }
                </div>
            )
        )}
    </div>

);

Indicator.propTypes = {
    /** Block button; label default text continue  */
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    /** Block button; event executes a certain functionality  */
    current: PropTypes.number.isRequired,
};

export default Indicator;
