import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Linker from '../Linker';
import {
    menuItem,
    activeLink,
    navOpenButton,
    closedBtn,
    dropdown,
    dropdownContent,
    navContainer,
    articleSourceWrapper,
    closed,
    opened,
    dropBtn,
    dropCont,
    btnDropClose,
    btnDropOpen,
    navContent,
    isOpened,
    isClosed,
} from './NavBar.module.scss';

import { testAttributes } from './Constants';

const NavBar = ({ navItems, locationPath }) => 
{
    const [toggledButtonId, setToggledButtonId] = useState(null);

    const toggleButton = (databaseId) =>
        databaseId === toggledButtonId
            ? setToggledButtonId('')
            : setToggledButtonId(databaseId);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const currentPage = locationPath !== undefined ? locationPath : ''; 
    
    const getLinkClass = (path, itemClass) => 
        (path === currentPage || `${path}/` === currentPage) ? `${itemClass} ${activeLink}` : itemClass;

    const toggle = () => setShowMobileMenu(!showMobileMenu);
    return (
        <nav
            role="navigation"
            itemScope
            itemType="https://schema.org/SiteNavigationElement"
            data-test={testAttributes.NAV_MENU_MAIN}
            className={navContent}
        >
            <button
                className={`${navOpenButton} 
                ${showMobileMenu ? closedBtn : 'opened'}`}
                type="button"
                onClick={toggle}
                data-test={testAttributes.NAV_MENU_MOBILE_BUTTON}
                aria-label="Navigation menu button"
            >
                <span />
                <span />
                <span />
            </button>
            <ul
                className={`${navContainer} ${
                    showMobileMenu ? opened : closed
                }`}
                data-test={testAttributes.NAV_MENU_CONTENT}
            >
                {navItems?.map(({ path, label, childItems, databaseId }) => 
                {
                    const isToggled = databaseId === toggledButtonId;
                    return (
                        <li
                            key={databaseId}
                            itemProp="name"
                            className={`${
                                childItems?.length ? dropdown : ''
                            } ${getLinkClass(path, menuItem)}`}
                        >
                            <Linker
                                itemProp="url"
                                href={path}
                                className={menuItem}
                                onClick={() => setShowMobileMenu(false)}
                                data-test={testAttributes.NAV_MENU_MAIN_ITEM}
                            >
                                <span itemProp="name">{label}</span>
                            </Linker>
                            {childItems?.length > 0 && (
                                <button
                                    data-test={
                                        testAttributes.NAV_MENU_MOBILE_DROPDOWN
                                    }
                                    className={`${dropBtn} ${
                                        isToggled ? btnDropOpen : btnDropClose
                                    } `}
                                    onClick={() => toggleButton(databaseId)}
                                    type="button"
                                    aria-label="Toggle Menu"
                                />
                            )}
                            {childItems?.length > 0 && (
                                <div className={dropCont}>
                                    <ul
                                        data-test={
                                            testAttributes.NAV_MENU_CHILD_CONTENT
                                        }
                                        className={`${dropdownContent} ${articleSourceWrapper}  ${
                                            isToggled ? isOpened : isClosed
                                        }`}
                                    >
                                        {childItems?.length > 0 &&
                                            childItems.map((child) => (
                                                <li
                                                    key={child.databaseId}
                                                    itemProp="name"
                                                >
                                                    <Linker
                                                        itemProp="url"
                                                        href={child.path}
                                                        className={`${menuItem}`}
                                                        onClick={() =>
                                                            setShowMobileMenu(
                                                                false
                                                            )
                                                        }
                                                        data-test={
                                                            testAttributes.NAV_MENU_MAIN_CHILD_ITEM
                                                        }
                                                    >
                                                        <span itemProp="name">
                                                            {child.label}
                                                        </span>
                                                    </Linker>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

NavBar.propTypes = {
    /** Items from nav bar (Desktop and Mobile) */
    navItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    /** Menu item is Highlighted according to location path */
    locationPath: PropTypes.string,
};

NavBar.defaultProps = {
    locationPath: '',
};

export default NavBar;
