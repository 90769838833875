export const testAttributes = {
    GLOBAL_FOOTER: 'global_footer',
    FOOTER_MENU: 'footer_menu',
    FOOTER_MENU_ITEM: 'footer_menu_item',
};

export const sampleAttributes = [
    {
        databaseId: 1270,
        label: 'LMB Mortgage Services, Inc. | NMLS #167283',
        path: '/company/',
    },
    {
        databaseId: 1280,
        label: '4859 W Slauson Ave #405 Los Angeles, CA 90056',
        path: '/address/',
    },
];

export const sampleAttributes2 = [
    {
        databaseId: 1282,
        label: 'Unsubscribe',
        path: '/unsubscribe/',
    },
    {
        databaseId: 1283,
        label: 'Do Not Sell My Personal Information ',
        path: '/personal-information/',
    },
];

export const sampleAttributes3 = [
    {
        databaseId: 1284,
        label: 'Terms and Conditions ',
        path: '/terms/',
    },
    {
        databaseId: 1285,
        label: 'Our Privacy Notice',
        path: '/personal-information/',
    },
    {
        databaseId: 1286,
        label: 'State Privacy Notices',
        path: '/privacy-notices/',
    },
    {
        databaseId: 1287,
        label: 'Ad Targeting Policy',
        path: '/policy-targeting/',
    },
    {
        databaseId: 1288,
        label: ' Licenses and Disclosures',
        path: '/licenses/',
    },
    {
        databaseId: 1289,
        label: 'www.nmlsconsumeraccess.org',
        path: '/other/',
    },
];
