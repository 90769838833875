// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    MORTGAGE_FORMGENERATOR_CONTAINER: 'mortgage_formgenerator',
};

export const sampleAttributes = {
    data:  [
        {
            type: 'select',
            className: '',
            name: 'loan_amount',
            placeholder: 'Loan Amount',
            validate: {
                required: true
            },
            data: []
        },
        {
            type: "number",
            className: "",
            name: "income",
            placeholder: "Gross Monthly Income",
            validate: {
                required: true
            }
        },
        {
            type: "input",
            className: "",
            name: "income",
            placeholder: "Gross Monthly Income",
            validate: {
                required: true
            }
        },
        {
            type: "checkbox",
            className: "",
            name: "mortgage-comparison-15-year-fixed",
            placeholder: "15 Year Fixed",
            validate: {
                required: true
            }
        },
        {},
        {
            type: 'button',
            label: 'Calculate',
            submit: true,
        }
    ],
};

// comment for test, please remove it
