import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import FormError from '../FormError/index';
import addCommaNum from '../../../utilities/addCommaNumber/index';

import { testAttributes } from './Constants';
import {
    inputContainer,
    inputForm,
    labelForm,
    tipForm,
    inputFormError,
    iconContainer,
    icon,
} from './Input.module.scss';

const Input = ({
    currency,
    validate,
    errors,
    name,
    placeholder,
    attributes,
    type,
    label,
    tipText,
    action,
    inputPattern,
    controller: Controller,
    control,
}) => (
    <div
        className={inputContainer}
        data-test={testAttributes.MORTGAGE_INPUT_CONTAINER}
    >
        <label className={labelForm} htmlFor={name}>
            {label}
        </label>
        <span className={tipForm}>{tipText}</span>
        <Controller
            name={name}
            control={control}
            rules={validate}
            render={({ field: { onChange, value, name: iName } }) => (
                <input
                    {...attributes}
                    value={value || ''}
                    onChange={(e) => 
                    {
                        if (
                            e.target.value === '' ||
                            (inputPattern && inputPattern.test(e.target.value))
                        ) 
                        {
                            // eslint-disable-next-line no-unused-expressions
                            currency
                                ? onChange(addCommaNum(e.target.value))
                                : onChange(e.target.value);
                        }
                        else if (!inputPattern) 
                        {
                            onChange(e.target.value);
                        }
                    }}
                    name={iName}
                    placeholder={placeholder}
                    type={type}
                    className={
                        errors[name]
                            ? `${inputForm} ${inputFormError}`
                            : inputForm
                    }
                    data-test={`${testAttributes.MORTGAGE_INPUT}${name}`}
                    onBlur={action.handleInputValidation}
                    onFocus={action.handleOnfocus}
                />
            )}
        />
        {errors[name] && (
            <>
                <div className={iconContainer}>
                    <Icon
                        data-test={testAttributes.MORTGAGE_INPUT_ERROR_ICON}
                        className={icon}
                        icon="carbon:warning"
                    />
                </div>
                <FormError errorMessage={errors[name].message} />
            </>
        )}
    </div>
);
Input.propTypes = {
    /** Object with validate rules */
    validate: PropTypes.objectOf(PropTypes.any),
    /** object with validation errors */
    errors: PropTypes.objectOf(PropTypes.object),
    /** Object with attributes of form element  */
    attributes: PropTypes.objectOf(PropTypes.any),
    /** Input name */
    name: PropTypes.string.isRequired,
    /** Wrapper component for controlled inputs */
    controller: PropTypes.func.isRequired,
    /** Input placeholder or label */
    placeholder: PropTypes.string.isRequired,
    /** Input label */
    label: PropTypes.string,
    /** Input tip text */
    tipText: PropTypes.string,
    /** Input type (text, number) default text */
    type: PropTypes.string,
    /** Input currency, check if numbers can have commas */
    currency: PropTypes.bool,
    /** Input actions, object of functions */
    action: PropTypes.objectOf(PropTypes.any),
    /** contains methods for registering components */
    control: PropTypes.objectOf(PropTypes.any),
    /** Regex to format input */
    inputPattern: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(RegExp),
    ]),
};

Input.defaultProps = {
    attributes: {},
    validate: {},
    errors: {},
    label: '',
    tipText: '',
    type: 'text',
    currency: false,
    action: {},
    inputPattern: '',
    control: {},
};

export default Input;
