// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var navContainer = "D_bf";
export var deskAppear = "D_cd";
export var mobAppear = "D_cf";
export var closed = "D_cg";
export var opened = "D_ch";
export var menuItem = "D_bj";
export var activeLink = "D_cj";
export var articleSourceWrapper = "D_8";
export var active = "D_bk";
export var dropdown = "D_bl";
export var dropdownContent = "D_br";
export var isOpened = "D_bg";
export var isClosed = "D_bh";
export var dropBtn = "D_bt";
export var btnDropClose = "D_bq";
export var btnDropOpen = "D_bv";
export var dropCont = "D_ck";
export var navOpenButton = "D_cl";
export var closedBtn = "D_cm";