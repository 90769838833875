const mergeExceptions = (inputException, existingExceptions = {}) => 
{
    const mergedExceptions = { ...existingExceptions };
    Object.keys(inputException).forEach((key) => 
    {
        let mergedArray = existingExceptions[key] || [];
        mergedArray = [...new Set([...mergedArray, ...inputException[key]])];
        mergedExceptions[key] = mergedArray;
    });
    return mergedExceptions;
};

export default mergeExceptions;
