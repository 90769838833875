// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var boxContainer = "y_bP";
export var boxInfo = "y_bQ";
export var boxNotice = "y_bR";
export var boxDefinition = "y_bS";
export var boxSnippet = "y_bT";
export var boxIcon = "y_bV";