import HTMLReactParser from 'html-react-parser';
import sanitizeHTML from './purify';
import parserOptions from './parse';

const parseHTML = (htmlString, parser = HTMLReactParser.default) => 
{
    const sanitized = sanitizeHTML(htmlString);
    return parser(sanitized, parserOptions);
};

export default parseHTML;
