export const testAttributes = {
    FOOTER_MAIN_CONTAINER: 'footer_main_container',
    FOOTER_SOCIALROW: 'footer_socialrow',
    FOOTER_MIDDLEROW: 'footer_middlerow',
    FOOTER_FOOTERMENU1: 'footer_footermenu_1',
    FOOTER_FOOTERMENU2: 'footer_footermenu_2',
    FOOTER_FOOTERMENU3: 'footer_footermenu_3',
    FOOTER_BOTTOMROW: 'footer_bottomrow',
};

export const sampleAttributes = {
    listMenu: {
        companyMenu: [
            {
                databaseId: 1270,
                label: 'LMB Mortgage Services, Inc. | NMLS #167283',
                path: '/company/',
            },
            {
                databaseId: 1280,
                label: '4859 W Slauson Ave #405 Los Angeles, CA 90056',
                path: '/address/',
            },
        ],
        personalMenu: [
            {
                databaseId: 1282,
                label: 'Unsubscribe',
                path: '/unsubscribe/',
            },
            {
                databaseId: 1283,
                label: 'Do Not Sell My Personal Information ',
                path: '/personal-information/',
            },
        ],
        policyMenu: [
            {
                databaseId: 1284,
                label: 'Terms and Conditions ',
                path: '/terms/',
            },
            {
                databaseId: 1285,
                label: 'Our Privacy Notice',
                path: '/personal-information/',
            },
            {
                databaseId: 1286,
                label: 'State Privacy Notices',
                path: '/privacy-notices/',
            },
            {
                databaseId: 1287,
                label: 'Ad Targeting Policy',
                path: '/policy-targeting/',
            },
            {
                databaseId: 1288,
                label: ' Licenses and Disclosures',
                path: '/licenses/',
            },
            {
                databaseId: 1289,
                label: 'www.nmlsconsumeraccess.org',
                path: '/other/',
            },
        ],
    },
    links: {
        facebook: 'https:www.google.com',
        twitter: 'https:www.google.com',
        pinterest: 'https:www.google.com',
        youtube: 'https:www.google.com',
        instagram: 'https:www.google.com',
    },
};

export const defaultAttributes = {};
