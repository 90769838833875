const placeholderDomain = "xx.xx";
const getURL = (url) => 
{
    let urlOb;
    try 
    {
        urlOb = new URL(url, `http://${placeholderDomain}`);
    }
    catch (er) 
    {
        return false;
    }
    return urlOb;
};

const getRelativePath = (url) => 
{
    if (
        url.substring(0, 4).toLowerCase() === "http" ||
		url.substring(0, 2) === "//"
    ) 
    {
        const urlOb = getURL(url);
        return urlOb.pathname;
    }
    return url;
};

export default getURL;
export { placeholderDomain, getRelativePath };
