import { attributesToProps } from 'html-react-parser';
import wpEmbedClass from './Constants';
import embeds from './embeds';

const toEmbed = ({ name, type, attribs }) => 
{
    if (type !== 'tag') return null;
    if (!name || name !== 'kbd') return null;
    if (!attribs?.class) return null;
    if (!attribs.class.split(' ').includes(wpEmbedClass)) return null;

    const props = attributesToProps(attribs);
    return embeds(props);
};
export default toEmbed;
