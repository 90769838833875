import * as GatsbyConfigs from '../../../../../../gatsby-config';
import getURL, { placeholderDomain } from '../getURL';
import { localHostnames } from '../../Constants';

const urlIsLocal = (url) => 
{
    const urlOb = getURL(url);
    if (!urlOb) return false;
    const urlHost = urlOb.host || urlOb.hostname;

    /** 1. Relative URL */
    if (urlHost === placeholderDomain) return true;

    /** 2. localhost: */
    if (urlOb.protocol === 'localhost:') return true;

    /** 3. URL matches WP GraphQL host */
    const wpSource =
        GatsbyConfigs.plugins &&
        GatsbyConfigs.plugins.find(
            (cfg) => cfg.resolve === 'gatsby-source-wordpress'
        );
    if (wpSource && urlHost === wpSource.options?.url?.toLowerCase())
        return true;

    /** 4. one of the localHosts */
    let isLocalHostname = false;
    localHostnames.forEach((hostname) => 
    {
        const re = new RegExp(hostname);
        if (re.test(urlHost)) 
        {
            isLocalHostname = true;
            return false;
        }
        return true;
    });
    if (isLocalHostname) return true;

    return false;
};

export default urlIsLocal;
