// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    SOCIALLINKS_MAIN_CONTAINER: 'sociallinks_main_container',
    SOCIALLINKS_LINK: 'sociallinks_link',
    SOCIALLINKS_ICON: 'sociallinks_icon',
};
export const sampleAttributes = {
    links: {
        facebook: 'https:www.google.com',
        instagram: 'https:www.google.com',
        linkedin: 'https:www.google.com',
        twitter: 'https:www.google.com',
        youtube: 'https:www.google.com',
        pinterest: 'https:www.google.com',
        soundcloud: 'https:www.google.com',
        tumblr: 'https:www.google.com',
    },
};
export const defaultAttributes = {
    SOCIALLINKS_MAIN_CONTAINER: 'sociallinks_main_container',
    SOCIALLINKS_LINK: 'sociallinks_link',
    SOCIALLINKS_ICON: 'sociallinks_icon',
};
