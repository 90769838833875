import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes } from './Constants';
import {
    footerContainer,
    footerMenu,
    menuItem,
    orderVertical,
    orderHorizontal,
    itemSeparator,
    blankSeparator,
} from './FooterMenu.module.scss';
import Linker from '../Linker';

export const listStyles = {
    horizontal: orderHorizontal,
    vertical: orderVertical,
};

const getStyle = (typeOrder) => listStyles[typeOrder];

const getSeparator = (separator) =>
    separator ? itemSeparator : blankSeparator;

export const footerList = (footerNav, typeOrder, separator) =>
    footerNav?.map((node) => (
        <li
            className={`${getStyle(typeOrder)} ${getSeparator(separator)}`}
            key={node.databaseId}
        >
            <Linker
                href={node.path}
                data-test={testAttributes.FOOTER_MENU_ITEM}
                className={menuItem}
            >
                {node.label}
            </Linker>
        </li>
    ));
/**
 Component that allows displaying a list of links in two horizontal or vertical modes allowing adding the `pipe |` when it is in mobile view.
 */
const FooterMenu = ({ footerNav, typeOrder, separator }) => (
    <div className={footerContainer} data-test={testAttributes.GLOBAL_FOOTER}>
        <ul
            key={footerNav.length}
            data-test={testAttributes.FOOTER_MENU}
            className={footerMenu}
        >
            {footerList(footerNav, typeOrder, separator)}
        </ul>
    </div>
);

FooterMenu.propTypes = {
    /** FooterMenu , list links to show in the Menu */
    footerNav: PropTypes.arrayOf(PropTypes.object).isRequired,
    /** typeOrder , property to define the orientation how each item will be displayed in desktop mode, remember that in mobile mode this orientation changes  */
    typeOrder: PropTypes.oneOf(Object.keys(listStyles)),
    /** separator , property to define if it is necessary to show the pipe | but only in mobile mode  */
    separator: PropTypes.bool,
};
FooterMenu.defaultProps = {
    typeOrder: 'horizontal',
    separator: true,
};

export default FooterMenu;
