// eslint-disable-next-line import/prefer-default-export
export const callback = (node) => 
{
    if ('target' in node && node.dataset?.xtarget) 
    {
        // node.setAttribute('target', '_blank');
        node.setAttribute('target', node.dataset.xtarget);
        if (node?.rel !== undefined) 
        {
            if (!node?.rel.includes('noopener')) 
            {
                node.setAttribute('rel', 'noopener');
            }
        }
        else 
        {
            node.setAttribute('rel', 'noopener');
        }
    }
    return node;
};
