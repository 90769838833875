export const testAttributes = {
    NAV_MENU_MAIN: 'nav_menu_main',
    NAV_MENU_CONTENT: 'nav_menu_content',
    NAV_MENU_CHILD_CONTENT: 'nav_menu_child_content',
    NAV_MENU_MAIN_ITEM: 'nav_menu_main_item',
    NAV_MENU_MAIN_CHILD_ITEM: 'nav_menu_main_child_item',
    NAV_MENU_MOBILE_BUTTON: 'nav_menu_mobile_button',
    NAV_MENU_MOBILE_DROPDOWN: 'nav_menu_mobile_dropdown',
};

export const sampleAttributes = [
    {
        databaseId: 176,
        label: 'Auto Insurance',
        path: '/iframe.html',
    },

    {
        databaseId: 177,
        label: 'Life Insurance',
        path: '/life-insurance/',
    },

    {
        databaseId: 178,
        label: 'Home Insurance',
        path: '/home-insurance/',
        childItems: [
            {
                databaseId: 1721,
                label: 'Learn About Auto Insurance',
                path: '/learning-center/auto-insurance/',
            },
            {
                databaseId: 1724,
                label: 'Learn About Life Insurance',
                path: '/learning-center/life-insurance/',
            },
            {
                databaseId: 1762,
                label: 'Learn About Health Insurance',
                path: '/learning-center/health-insurance/',
            },
            {
                databaseId: 1723,
                label: 'Learn About Home Insurance',
                path: '/learning-center/home-insurance/',
            },
        ],
    },

    {
        databaseId: 179,
        label: 'Health Insurance',
        path: '/health-insurance',
    },

    {
        databaseId: 1520,
        label: 'Learning Center',
        path: '/learning-center/',

        childItems: [
            {
                databaseId: 1521,
                label: 'Learn About Auto Insurance',
                path: '/learning-center/auto-insurance/',
            },
            {
                databaseId: 1524,
                label: 'Learn About Life Insurance',
                path: '/learning-center/life-insurance/',
            },
            {
                databaseId: 1522,
                label: 'Learn About Health Insurance',
                path: '/learning-center/health-insurance/',
            },
            {
                databaseId: 1523,
                label: 'Learn About Home Insurance',
                path: '/learning-center/home-insurance/',
            },
        ],
    },
];
