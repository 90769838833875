// eslint-disable-next-line import/prefer-default-export
export const mockRefinance = {
    "status": "SUCCESS",
    "message": null,
    "containsDefaultedRates": "ALL",
    "disregardCashout": false,
    "calcInfoMap": {
        "30Yr": {
            "newMonthlyPayment": 886,
            "ratesInfo": {
                "apr": 4.723,
                "interestRate": 4.375
            },
            "totalInterestSavings": 24390,
            "monthlyPaymentSavings": 164,
            "cashOut": 0
        },
        "5YrARM": {
            "newMonthlyPayment": 797,
            "ratesInfo": {
                "apr": 4.402,
                "interestRate": 3.5
            },
            "totalInterestSavings": null,
            "monthlyPaymentSavings": 253,
            "cashOut": 0
        },
        "CashOut": {
            "newMonthlyPayment": 1977,
            "ratesInfo": {
                "apr": 4.723,
                "interestRate": 4.375
            },
            "totalInterestSavings": -368370,
            "monthlyPaymentSavings": -927,
            "cashOut": 218500
        },
        "15Yr": {
            "newMonthlyPayment": 1258,
            "ratesInfo": {
                "apr": 4.001,
                "interestRate": 3.375
            },
            "totalInterestSavings": 116910,
            "monthlyPaymentSavings": -208,
            "cashOut": 0
        }
    }
}

export const mockCalculator = { 
    "status": "SUCCESS",
    "message": null,
    "containsDefaultedRates": "ALL",
    "calcInfoMap": { 
        "30Yr": { 
            "monthlyPayment": 964, 
            "ratesInfo": { 
                "apr": 4.232, "interestRate": 3.875 
            }, 
            "totalInterest": 142040
        }, 
        "5YrARM": { 
            "monthlyPayment": 921, 
            "ratesInfo": { 
                "apr": 4.402, 
                "interestRate": 3.5 
            }, 
            "totalInterest": 126560 
        }, 
        "15Yr": { 
            "monthlyPayment": 1415, 
            "ratesInfo": { 
                "apr": 3.607, 
                "interestRate": 2.99 
            }, 
            "totalInterest": 49700 
        }
    } 
}

export const mockComparison = {
    "nodes": [
        {
            "title": "HARP Refinance",
            "content": "<ul>\n<li>Current on your mortgage</li>\n<li>No 30 day+ late payments in last six months</li>\n<li>No more than one in the past 12 months</li>\n<li>Must be for primary residence</li>\n<li>Loan is owned by Freddie Mac or Fannie Mae</li>\n<li>Loan was originated on or before May 31, 2009</li>\n<li>Current loan to value ratio must be greater than 80%</li>\n<li>Credit score requirement varies by lender</li>\n</ul>\n",
            "cdmfields": {
                "checked": "No",
                "disclaimer": "Yes",
                "getCash": "No",
                "lowerPayment": "Yes",
                "lowerRate": "No",
                "payLess": "No",
                "payOff": "No",
                "shortTitle": "HARP",
                "sort": 7
            }
        },
        {
            "title": "VA Loan",
            "content": "<ul>\n<li>Served 90 consecutive days of active service during wartime</li>\n<li>Served 181 days of active service during peacetime</li>\n<li>Have more than 6 years of service in the National Guard or Reserves</li>\n<li>You are the spouse of a service member who has died in the line of duty</li>\n<li>Must maintain a certain amount of income left over each month</li>\n<li>Satisfactory credit of around 600</li>\n</ul>\n",
            "cdmfields": {
                "checked": "No",
                "disclaimer": "Yes",
                "getCash": "No",
                "lowerPayment": "Yes",
                "lowerRate": "No",
                "payLess": "No",
                "payOff": "No",
                "shortTitle": "VA",
                "sort": 5
            }
        },
        {
            "title": "FHA Loan",
            "content": "<ul>\n<li>Steady employment over the past 2 years</li>\n<li>Valid SSN</li>\n<li>Must be for primary residence</li>\n<li>Loan must be from an approved FHA lender</li>\n<li>Have to pay mortgage insurance premiums</li>\n<li>Allows lower credit, minimum of 500</li>\n<li>Low down payment with a minimum of 3.5%</li>\n</ul>\n",
            "cdmfields": {
                "checked": "No",
                "disclaimer": "Yes",
                "getCash": "No",
                "lowerPayment": "Yes",
                "lowerRate": "No",
                "payLess": "No",
                "payOff": "No",
                "shortTitle": "FHA",
                "sort": 6
            }
        },
        {
            "title": "5/1 Yr Adjustable",
            "content": "<ul>\n<li>Excellent credit, likely needs to be 700+</li>\n<li>At least 5% down payment</li>\n<li>Steady income</li>\n</ul>\n",
            "cdmfields": {
                "checked": "No",
                "disclaimer": "No",
                "getCash": "No",
                "lowerPayment": "Yes",
                "lowerRate": "Yes",
                "payLess": "No",
                "payOff": "No",
                "shortTitle": "5/1 ARM",
                "sort": 3
            }
        },
        {
            "title": "Cash Out Refinance",
            "content": "<ul>\n<li>Must have owned current home for at least a year</li>\n<li>Must have a minimum credit score-usually higher than a normal/previous refinance requirement</li>\n<li>Current loan to value ratio must be around 85%</li>\n</ul>\n",
            "cdmfields": {
                "checked": "No",
                "disclaimer": "No",
                "getCash": "Yes",
                "lowerPayment": "Depends On Term",
                "lowerRate": "Depends On Term",
                "payLess": "Depends On Term",
                "payOff": "Depends On Term",
                "shortTitle": "Cash Out",
                "sort": 4
            }
        },
        {
            "title": "15 Yr Fixed",
            "content": "<ul>\n<li>Low to no debt</li>\n<li>Excellent credit, likely needs to be 700+</li>\n<li>Steady income</li>\n</ul>\n",
            "cdmfields": {
                "checked": "No",
                "disclaimer": "No",
                "getCash": "No",
                "lowerPayment": "No",
                "lowerRate": "Yes",
                "payLess": "Yes",
                "payOff": "Yes",
                "shortTitle": "15 Year Fixed",
                "sort": 2
            }
        },
        {
            "title": "30 Yr Fixed",
            "content": "<ul>\n<li>Good to excellent credit, likely needs to be 600+</li>\n<li>Steady income</li>\n<li>At least 3% down payment</li>\n</ul>\n",
            "cdmfields": {
                "checked": "No",
                "disclaimer": "No",
                "getCash": "No",
                "lowerPayment": "Yes",
                "lowerRate": "No",
                "payLess": "No",
                "payOff": "No",
                "shortTitle": "30 Yr Fixed",
                "sort": 1
            }
        }
    ]
};