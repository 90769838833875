/**
 * rules for allowed iframe sources, e.g. /^youtu.be or /^youtube.com
 */
const iframeUrlAllowed = (src) => 
{
    try 
    {
        const urlObject = new URL(src);
        if (/^[^\.]*\.?youtu\.?be(\.com)?$/.test(urlObject.host)) return true;
        return false;
    }
    catch (err) 
    {
        return false;
    }
};
/**
 * if this is an iframe, and it does not pass above tests, remove it
 */
const callback = (node, data) => 
{
    if ('target' in node && node.target?.length) 
    {
        node.setAttribute('data-xtarget', node.target);
    }
    if (data.tagName !== 'iframe') return;
    if (iframeUrlAllowed(node.src)) return;
    node.parentNode.removeChild(node);
};
/**
 * by default, DOMPurify strips iframe tags and many related attributes.
 * These exeptions allow that tag and attributes, so youtube players work
 */
const exceptions = {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: [
        'loading',
        'title',
        'width',
        'height',
        'src',
        'frameborder',
        'allow',
        'allowfullscreen',
    ],
};

export { callback };
export default exceptions;
