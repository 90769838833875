import DOMPurify from 'isomorphic-dompurify';
import mergeExceptions from '../../mergeExceptions';

import youtubeExceptions, { callback as youtubeIframe } from './youtubeIframe';

const uponseCallbacks = [youtubeIframe];

const uponseExceptions = [youtubeExceptions];

// eslint-disable-next-line import/no-mutable-exports
let allUponseExceptions = {};

uponseCallbacks.forEach((uponseCallback) => 
{
    DOMPurify.addHook('uponSanitizeElement', uponseCallback);
});

uponseExceptions.forEach((uponseException) => 
{
    allUponseExceptions = mergeExceptions(uponseException, allUponseExceptions);
});

export default allUponseExceptions;
