import React from 'react';
import { domToReact, attributesToProps } from 'html-react-parser';
import Linker from '../../../../../components/simple/Linker';

const toLinker = ({ name, type, attribs, children }) => 
{
    if (type !== 'tag') return null;
    if (!name || name !== 'a') return null;
    if (attribs !== undefined) 
    {
        if (typeof attribs.target !== 'undefined') return null;
    }

    const props = attributesToProps(attribs);

    return <Linker {...props}>{domToReact(children)}</Linker>;
};

export default toLinker;
