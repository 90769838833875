export const attributeToolType = 'data-tooltype';
export const dataToolResultsContainer = 'data-toolresults-container';
export const dataResultsField = 'data-resultfield';
export const TOOL_TYPES = {
    MORTGAGE_CALCULATOR: "mortgageCalculator",
    MORTGAGE_COMPARISON: "mortgageComparison",
    REFINANCE_MORTGAGE: "refinanceMortgage",
    DEBT_TO_INCOME: "debtToIncome",
}
export const LOAN_TYPE_COLUMNS = {
    "fixed30": "n30Yr",
    "fixed15": "n15Yr",
    "arm5": "n5YrARM",
    "cashout": "CashOut"
}

export const testContent = `
<div class="lmb-tool-container" data-test="MORTGAGE_SAVE_MORTGAGE" data-toolattributes="{&quot;toolType&quot;:&quot;mortgageCalculator&quot;}">
	<div data-tooltype="mortgageCalculator" data-test="MORTGAGE_MAIN_CONTAINER">
		<h2 class="wp-block-cdm-toolblock-toolheader" data-test="cdmToolBlockHeader">
			<span>Start Here</span>
		</h2>
		<div class="wp-block-cdm-toolblock-toolresults" data-toolresults-container="true" data-test="cdmToolBlockResults">
			<span class="lmb-mortgage-results-fields result-field-cashout" data-resultfield="cashout">N/A</span>
			<span class="lmb-mortgage-results-fields result-field-fixed30" data-resultfield="fixed30">
				<a href="https://test-lmb.pantheonsite.io/guides/first-time-homebuyers-guide/" data-type="guide" data-id="16016">See More</a>
			</span>
			<span class="lmb-mortgage-results-fields result-field-fixed15" data-resultfield="fixed15">
				<a href="https://test-lmb.pantheonsite.io/learn/buying-a-home/what-is-the-cost-of-home-warranty/" data-type="post" data-id="14658">See More</a>
			</span>
			<span class="lmb-mortgage-results-fields result-field-arm5" data-resultfield="arm5">
				<a href="https://test-lmb.pantheonsite.io/learn/owning-a-home/when-is-balance-transfer-a-good-idea/" data-type="post" data-id="12571">See More</a>
			</span>
			<span class="lmb-mortgage-results-fields result-field-unknown" data-resultfield="unknown">
				<a href="/" data-type="post" data-id="12571">See More</a>
			</span>
		</div>
	</div>
</div> 
`

export const testElements = toolType => ({
    "name": "div",
    "type": "tag",
    "attribs": {
        "data-test": "MORTGAGE_MAIN_CONTAINER",
        "data-tooltype": toolType
    },
    "children": [
        {
            name: "h2",
            type: "tag",
            children: [{
                type: 'text',
                data: 'Zam Bomba',
            }]
        },
        {
            attribs: {
                [dataToolResultsContainer]: "true"
            },
            children: [
                {
                    name: "span",
                    type: "tag",
                    attribs: {
                        [dataResultsField]: "zam",
                    },
                    children: [{
                        type: 'text',
                        data: 'zambomba',
                    }]
                },
                {
                    name: "span",
                    type: "tag",
                    attribs: {},
                    children: []
                },
                {
                    name: "div",
                    type: "tag",
                    attribs: {},
                    children: []
                },
                {
                    type: "text",
                    data: "\n"
                }
            ]
        }
    ]
})