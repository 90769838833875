import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { testAttributes } from './Constants';
import { itemLink, itemIcon } from './SocialLinks.module.scss';

export const listSocial = {
    facebook: {
        icon: 'dashicons:facebook-alt',
    },
    instagram: {
        icon: 'dashicons:instagram',
    },
    linkedin: {
        icon: 'dashicons:linkedin',
    },
    twitter: {
        icon: 'dashicons:twitter',
    },
    youtube: {
        icon: 'dashicons:youtube',
    },
    pinterest: {
        icon: 'dashicons:pinterest',
    },
    soundcloud: {
        icon: 'bx:bxl-soundcloud',
    },
    tumblr: {
        icon: 'brandico:tumblr',
    },
};

export const fnGetIcon = (icon) =>
    listSocial[icon] ? listSocial[icon].icon : 'dashicons:admin-users';

export const fnGetIconClass = (addIconClass) => addIconClass || itemIcon;

const SocialLinks = ({ links, addClass, addIconClass }) => (
    <div
        data-test={testAttributes.SOCIALLINKS_MAIN_CONTAINER}
        className={addClass}
        itemScope
        itemType="https://schema.org/SocialMediaPosting"
    >
        {Object.keys(links).length > 0 &&
            Object.keys(links).map((item) => (
                <a
                    key={item}
                    href={links[item]}
                    rel="noreferrer"
                    target="_blank"
                    className={itemLink}
                    data-test={testAttributes.SOCIALLINKS_LINK}
                    itemProp="url"
                    aria-label={item}
                >
                    <Icon
                        icon={fnGetIcon(item)}
                        className={fnGetIconClass(addIconClass)}
                        data-test={testAttributes.DISCLOSURETOOLTIP_ICON}
                        itemProp="image"
                    />
                </a>
            ))}
    </div>
);
SocialLinks.propTypes = {
    links: PropTypes.objectOf(PropTypes.any),
    addClass: PropTypes.string,
    addIconClass: PropTypes.string,
};

SocialLinks.defaultProps = {
    links: [],
    addClass: undefined,
    addIconClass: undefined,
};
export default SocialLinks;
