import React from "react";
import PropTypes from "prop-types";
import { Icon } from '@iconify/react';

import { testAttributes, tableVerticalTitles, defaultValues } from './Constants';
import {tableContainer, tableTitles, tableContent, checkmark, xmark, titleContainer} from './MortgageComparison.module.scss';

const MortgageComparison = ({userFormData, title, loandTypesDataNodes}) => 
{
     
    /** Function used to determine what type of data to display (String, X icon, Checkmark icon) */
    const contentToShow = (value) => 
    {
        let elementToShow = value;
        if(value === 'Yes') 
        {
            elementToShow = <Icon className={checkmark} icon="ci:check" data-test={testAttributes.MORTGAGE_CHECKMARK} />;
        }
        if (value === 'No') 
        {
            elementToShow = <Icon className={xmark} icon="ci:close-small" data-test={testAttributes.MORTGAGE_CLOSE_ICON} />;
             
        }
        return elementToShow
    }

    return (
        <>
            <div className={titleContainer} data-test={testAttributes.MORTGAGE_TABLE_TITLE_CONTAINER}>
                <h2>{title}</h2>
            </div>
            <div className={tableContainer} data-test={testAttributes.MORTGAGE_TABLE_CONTAINER}>
                <div className={tableTitles} data-test={testAttributes.MORTGAGE_TABLE_COLUMN_TITLES}>
                    {
                        tableVerticalTitles.map(element => (
                            <div key={element.id}>
                                <span>{element.title}</span>
                            </div>
                        ))
                    }
                </div>
                {
                    loandTypesDataNodes.map(tableElement => (
                        userFormData.mortgageCheckbox.includes(`mortgage-comparison-${tableElement.cdmfields.shortTitle.replace(/ |\//g, "-").toLowerCase()}`) &&
                    <div className={tableContent} data-test={testAttributes.MORTGAGE_TABLE_CONTENT} key={tableElement.cdmfields.sort}>
                        <div>
                            <span>{tableElement.title}</span>
                        </div>
                        <div>
                            <span>{contentToShow(tableElement.cdmfields.getCash)}</span>
                        </div>
                        <div>
                            <span>{contentToShow(tableElement.cdmfields.lowerPayment)}</span>
                        </div>
                        <div>
                            <span>{contentToShow(tableElement.cdmfields.lowerRate)}</span>
                        </div>
                        <div>
                            <span>{contentToShow(tableElement.cdmfields.payOff)}</span>
                        </div>
                        <div>
                            <span>{contentToShow(tableElement.cdmfields.payLess)}</span>
                        </div>
                    </div>
                    )).sort((columnA,columnB) => columnA.key > columnB.key ? 1 : -1)
                }
            </div>
        </>
    );
};

MortgageComparison.propTypes = {
    /** data of the checkboxes selected by the user props */
    userFormData: PropTypes.objectOf(Object).isRequired,
    /** array of WP-generated loan types / params to display in table */
    loandTypesDataNodes: PropTypes.arrayOf(Object).isRequired,
    /** title for table, optional, default "result" */
    title: PropTypes.string,
}

MortgageComparison.defaultProps = {
    title: defaultValues.MORTGAGE_TABLE_TITLE,
}

export default MortgageComparison;