import React from 'react';

import { domToReact } from 'html-react-parser';

const CalloutBox = require('../../../../../components/simple/CalloutBox')
    .default;

const toCalloutBox = ({
    name, type, attribs, children,
}) => 
{
    if (type !== 'tag') return;
    if (!name || name !== 'aside') return;
    const inputClassNames = attribs?.class?.split(' ') || [];

    if (!inputClassNames.includes('cdm-callout-box')) return;

    const calloutBoxType = inputClassNames.reduce((callBoxtype, callBoxname) => 
    {
        if (callBoxname.substring(0, 13) === 'callout-type-') 
        {
            return callBoxname.split('-').pop();
        }
        return callBoxtype;
    }, '');

    const boxContentItems = Array.isArray(children)
        ? children.filter((child) => 
{
            if (!child.name || child.name !== 'span') return true;

            const childClasses = child?.attribs?.class?.split(' ') || [];
            if (!childClasses || !childClasses.includes('cdm-callout-icon')) return true;
            return false;
        })
        : children;

    let convertedContent = domToReact(boxContentItems);
    if (
        !Array.isArray(convertedContent) && typeof convertedContent !== 'string'
    ) 
    {
        convertedContent = [convertedContent];
    }

    // eslint-disable-next-line consistent-return
    return React.createElement(CalloutBox, {
        boxType: calloutBoxType,
        content: convertedContent,
    });
};

export default toCalloutBox;
