/* eslint no-param-reassign: "error" */
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import Link from 'gatsby-link';
import { useFeatures } from '@paralleldrive/react-feature-toggles';
import {
    getRelativePath,
    renderLink,
    targetEmpty,
    addRelAttribute,
} from './helpers';
import { testAttributes } from './Constants';

/**
 If `href` links to an onsite path, and the path is released, then
 render as a &lt;Link&gt; component (linked page is pre-cached). Otherwise,
 render as an ordinary &lt;a&gt; anchor tag.

 Furthermore, if link is intended to open in another window, then render as an &lt;a&gt; anchor tag.

 HTML source shows an &lt;a&gt; tag in all cases, but with a different "data-test" attribute.
 */
const Linker = ({ href, children, relative, ...props }) => 
{
    const features = useFeatures();
    let elementToCreate = 'a';
    if (!targetEmpty(props)) 
    {
        addRelAttribute(props);
    }
    else if (renderLink({ href, features })) 
    {
        elementToCreate = Link;
    }
    switch (elementToCreate) 
    {
        case 'a': {
            props['data-test'] = testAttributes.A;
            props.href = href;
            break;
        }
        default: {
            props['data-test'] = testAttributes.LINK;
            props.to = relative ? getRelativePath(href) : href;
        }
    }
    return React.createElement(elementToCreate, props, children);
};

Linker.propTypes = {
    /** target URL, evaluated to determine if local or offsite link */
    href: PropTypes.string,
    /** relative - if local URL, change href to path only (default true) */
    relative: PropTypes.bool,
    children: PropTypes.node,
};

Linker.defaultProps = {
    href: '',
    relative: true,
    children: false,
};

export default Linker;
export { renderLink };
