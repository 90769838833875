// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    MORTGAGE_DROPDOWN: 'mortgage_dropdown_',
    MORTGAGE_DROPDOWN_CONTAINER: 'mortgage_dropdown_container',
    MORTGAGE_DROPDOWN_ERROR_ICON: 'mortgage_dropdown_error_icon',
};

export const sampleAttributes = {
    data: [
        {
            "value": "740",
            "label": "Excellent (720 or above)"
        },
        {
            "value": "670",
            "@selected": "selected",
            "label": "Good (620 - 719)"
        },
        {
            "value": "599",
            "label": "Fair (580-619)"
        },
        {
            "value": "559",
            "label": "Needs Improvement (540-579)"
        },
        {
            "value": "500",
            "label": "Poor (539 or lower)"
        }
    ],
};