import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { siteConstants } from './Constants';
/**
 Component that wraps a &lt;Helmet&gt; component, adding important metadata to the html <HEAD> area
 */
const Seo = ({
    helmetProps,
    lang,
    bodyClass,
    bodyAttrs,
    id,
    helmetElement,
}) => 
{
    const { children, ...allHelmetProps } = helmetProps;
    const bodyAttributes = { ...bodyAttrs };
    allHelmetProps.htmlAttributes = { lang };
    if (bodyClass && bodyClass.length) 
    {
        if (bodyAttributes.class) 
        {
            bodyAttributes.class += ` ${bodyClass}`;
        }
        else 
        {
            bodyAttributes.class = bodyClass;
        }
    }
    if (id && typeof id === 'number') 
    {
        bodyAttributes['data-id'] = id.toString();
    }
    else if (id && id.length) 
    {
        bodyAttributes['data-id'] = id;
    }
    allHelmetProps.bodyAttributes = bodyAttributes;
    return React.createElement(helmetElement, { ...allHelmetProps }, children);
};

Seo.propTypes = {
    /** object of props ready for &lt;Helmet&gt; as generated by function: <tt>prepHelmetData()</tt> */
    helmetProps: PropTypes.objectOf(PropTypes.any),
    /** html lang attribute */
    lang: PropTypes.string,
    /** body className, is rendered in plain text as an attribute of the &lt;body&gt; tag */
    bodyClass: PropTypes.string,
    /** optional key:value pairs for additional body attributes */
    bodyAttrs: PropTypes.objectOf(PropTypes.any),
    /**  id of the post, category, author etc., appears as a data-id="XX" attribute */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** the component that renders the head props. Defaults to Helmet, but can be some other component for visibility */
    helmetElement: PropTypes.elementType,
};
Seo.defaultProps = {
    helmetProps: {
        title: `${siteConstants.name}`,
        meta: [
            { charset: 'utf-8' },
            { name: 'description', content: siteConstants.description },
        ],
    },
    lang: 'en',
    bodyClass: 'webpage',
    bodyAttrs: {},
    id: '',
    helmetElement: Helmet,
};

export default Seo;
