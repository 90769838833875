// Compoenents
import DebToIncome from '../../components/mortgage/Tables/DebToIncome';

// Functions to process data
import { debtToIncomeMortgage } from '../../utilities/mortgage';

export const dataForm = {
    title: 'Debt to income',
    endpoint: '',
    callBack: debtToIncomeMortgage,
    resultComponent: DebToIncome,
    showSteps: false,
    formScheme: [
        {
            step: 1,
            filter: () =>
                // @TODO: Just for test
                true,
            properties: [
                {
                    type: 'text',
                    label: 'Gross Monthly Income',
                    tipText: '',
                    name: 'income',
                    placeholder: '0',
                    inputPattern: /[+-]?([0-9]*[.])?[0-9]+/,
                    currency: true,
                    validate: {
                        required: {
                            value: true,
                            message: 'Please add a valid Gross Monthly Income',
                        },
                        pattern: {
                            value: /^(\d{1,3})(,\d{1,3})*(\d{1,})*(d{1,6}(?:[- ]?\d{4}))?$/,
                            message: 'Please add a valid Gross Monthly Income',
                        },
                    },
                    attributes: {
                        maxLength: 10,
                        inputMode: 'numeric',
                    },
                },
                {
                    type: 'text',
                    label: 'Monthly Mortgage/Rent',
                    tipText: '',
                    name: 'housing-payments',
                    placeholder: '0',
                    inputPattern: /[+-]?([0-9]*[.])?[0-9]+/,
                    currency: true,
                    validate: {
                        required: {
                            value: true,
                            message: 'Please add a valid Monthly Mortgage/Rent',
                        },
                        pattern: {
                            value: /^(\d{1,3})(,\d{1,3})*(\d{1,})*(d{1,6}(?:[- ]?\d{4}))?$/,
                            message: 'Please add a valid Monthly Mortgage/Rent',
                        },
                    },
                    attributes: {
                        maxLength: 10,
                        inputMode: 'numeric',
                    },
                },
                {
                    type: 'text',
                    label: 'Monthly Minimum Credit Card Payments',
                    tipText: '',
                    name: 'credit-payments',
                    placeholder: '0',
                    currency: true,
                    inputPattern: /[+-]?([0-9]*[.])?[0-9]+/,
                    validate: {
                        required: {
                            value: true,
                            message:
                                'Please add a valid Monthly Minimum Credit Card Payments',
                        },
                        pattern: {
                            value: /^(\d{1,3})(,\d{1,3})*(\d{1,})*(d{1,6}(?:[- ]?\d{4}))?$/,
                            message:
                                'Please add a valid Monthly Minimum Credit Card Payments',
                        },
                    },
                    attributes: {
                        maxLength: 10,
                        inputMode: 'numeric',
                    },
                },
                {
                    type: 'text',
                    label: 'Monthly Non-Mortgage Loan Payments',
                    tipText: '',
                    name: 'loan-payments',
                    placeholder: '0',
                    inputPattern: /[+-]?([0-9]*[.])?[0-9]+/,
                    currency: true,
                    validate: {
                        required: {
                            value: true,
                            message:
                                'Please add a valid Monthly Non-Mortgage Loan Payments',
                        },
                        pattern: {
                            value: /^(\d{1,3})(,\d{1,3})*(\d{1,})*(d{1,6}(?:[- ]?\d{4}))?$/,
                            message:
                                'Please add a valid Monthly Non-Mortgage Loan Payments',
                        },
                    },
                    attributes: {
                        maxLength: 10,
                        inputMode: 'numeric',
                    },
                },
                {
                    type: 'button',
                    label: 'Calculate',
                    submit: true,
                },
            ],
        },
    ],
};

export default dataForm;
