import React from 'react';
import PropTypes from 'prop-types';

// Components
import Header from '../Header';
import Footer from '../Footer';

// Styles and constants
import { mainContent, rootPath } from './Layout.module.scss';
import { testAttributes } from './Constants';

/**
 Component that allows displaying the layout using 3 subcomponents ( Header, children, Footer  ).
 */
const Layout = ({ headerProps, children, footerProps, isPreview }) => (
    <div className={`${rootPath} ${isPreview && 'isPreview'} layout_root` } data-test={testAttributes.PAGE_ROOT}>
        <Header {...headerProps} />
        <main className={mainContent} data-test={testAttributes.MAIN_CONTAINER}>
            {children}
        </main>
        <Footer {...footerProps} />
    </div>
);

Layout.propTypes = {
    /** children, component to render in main content */
    children: PropTypes.node,
    /** header, Menu items, logo url and logo alt description */
    headerProps: PropTypes.shape({
        headerMenu: PropTypes.arrayOf(PropTypes.object),
        logoUrl: PropTypes.string,
        logoAlt: PropTypes.string,
    }).isRequired,
    /** footer, object with social links and items menu */
    footerProps: PropTypes.objectOf(PropTypes.object).isRequired,
    /** add, Preview watermark to layout */
    isPreview: PropTypes.bool,
};

Layout.defaultProps = {
    children: <div>Default content</div>,
    isPreview: false,
};

export default Layout;
