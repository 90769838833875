export const testAttributes = {
    HEADER_MAIN_CONTAINER: 'header_main_container',
    HEADER_CONTENT: 'header_content',
    HEADER_LOGO: 'header_logo',
    HEADER_LOGO_LINK: 'header_logo_link',
    HEADER_LOGO_IMG: 'header_logo_img',
    HEADER_MENU: 'header_menu',
    HEADER_MENU_NAVBAR: 'header_menu_nva_bar',
};

export const sampleAttributes = {
    headerMenu: [
        {
            databaseId: 176,
            label: 'Auto Insurance',
            path: '/iframe.html',
        },

        {
            databaseId: 177,
            label: 'Life Insurance',
            path: '/life-insurance/',
        },

        {
            databaseId: 178,
            label: 'Home Insurance',
            path: '/home-insurance/',
            childItems: [
                {
                    databaseId: 1521,
                    label: 'Learn About Auto Insurance',
                    path: '/learning-center/auto-insurance/',
                },
                {
                    databaseId: 1524,
                    label: 'Learn About Life Insurance',
                    path: '/learning-center/life-insurance/',
                },
                {
                    databaseId: 1522,
                    label: 'Learn About Health Insurance',
                    path: '/learning-center/health-insurance/',
                },
                {
                    databaseId: 1523,
                    label: 'Learn About Home Insurance',
                    path: '/learning-center/home-insurance/',
                },
            ],
        },

        {
            databaseId: 179,
            label: 'Health Insurance',
            path: '/health-insurance',
        },

        {
            databaseId: 1520,
            label: 'Learning Center',
            path: '/learning-center/',

            childItems: [
                {
                    databaseId: 1521,
                    label: 'Learn About Auto Insurance',
                    path: '/learning-center/auto-insurance/',
                },
                {
                    databaseId: 1524,
                    label: 'Learn About Life Insurance',
                    path: '/learning-center/life-insurance/',
                },
                {
                    databaseId: 1522,
                    label: 'Learn About Health Insurance',
                    path: '/learning-center/health-insurance/',
                },
                {
                    databaseId: 1523,
                    label: 'Learn About Home Insurance',
                    path: '/learning-center/home-insurance/',
                },
            ],
        },
    ],
};

export const defaultAttributes = {
    url: 'https://www.lowermybills.com/',
    alt: 'LowerMyBills Logo',
};
