/* eslint-disable react/prop-types */
import React from 'react';
import { domToReact } from 'html-react-parser';
import {
    attributeToolType,
    dataToolResultsContainer,
    dataResultsField,
    TOOL_TYPES,
    LOAN_TYPE_COLUMNS
} from "./Constants";
import MortgageContainer from '../../../../../components/forms/MortgageContainer';
import { dataForm as dataMortgageCalculator } from '../../../../../mortgage/scheme/mortgageCalculator';
import { dataForm as dataRefinanceCalculator } from '../../../../../mortgage/scheme/refinanceMortgageCalculator';
import { dataForm as dataDebtToIncome } from '../../../../../mortgage/scheme/debtToIncomeCalculator';
import { dataForm as dataMortgageComparison } from '../../../../../mortgage/scheme/mortgageComparison';

const extractToolResults = resultsChildren => 
{
    const resultsFields = {};
    if ( !Array.isArray( resultsChildren ) ) return null;
    resultsChildren.forEach( ({name, type, attribs, children }) =>
    {
        if ( type !== 'tag' ) return;
        if ( !name || name !== 'span' ) return;
        const resultField = attribs[dataResultsField] || false;
        if ( !resultField ) return;
        const columnName = LOAN_TYPE_COLUMNS[resultField] || resultField;
        resultsFields[ columnName ] = domToReact( children )
    })
    return resultsFields;
}

const toMortgageToolResults = ({name, type, attribs, children}) => 
{
    if ( type !== 'tag' ) return null;
    if ( !name || name !== 'div' ) return null;
    if ( !attribs ) return null;
    const dataToolType = attribs[ attributeToolType ];
    if ( typeof dataToolType === 'undefined' ) return null;

    const titleElement = children.find( child => child.type === 'tag' && child.name === 'h2' );
    const titleComponent = titleElement ? domToReact( titleElement.children ) : null;

    const toolResultsContainer = children.find( child => child.attribs && Object.keys( child.attribs).includes( dataToolResultsContainer ));
    const resultsFields = toolResultsContainer ? extractToolResults( toolResultsContainer.children ) : {}

    let dataFormProp;

    switch( dataToolType ) 
    {
        case TOOL_TYPES.MORTGAGE_COMPARISON: {
            dataFormProp = dataMortgageComparison;
            break;
        }
        case TOOL_TYPES.REFINANCE_MORTGAGE: {
            dataFormProp = dataRefinanceCalculator;
            break;
        }
        case TOOL_TYPES.DEBT_TO_INCOME: {
            dataFormProp = dataDebtToIncome;
            break;
        }
        default: {
            dataFormProp = dataMortgageCalculator
        }
    }
    dataFormProp.title = titleComponent;

    return <MortgageContainer 
        dataForm={dataFormProp}
        resultsFields={resultsFields}
    />

}
export default toMortgageToolResults;