// eslint-disable-next-line import/prefer-default-export
export const homeValue =  [
    {
        "value": "2500",
        "label": "$1 - $5,000"
    },
    {
        "value": "7500",
        "label": "$5,001 - $10,000"
    },
    {
        "value": "12500",
        "label": "$10,001 - $15,000"
    },
    {
        "value": "17500",
        "label": "$15,001 - $20,000"
    },
    {
        "value": "22500",
        "label": "$20,001 - $25,000"
    },
    {
        "value": "27500",
        "label": "$25,001 - $30,000"
    },
    {
        "value": "32500",
        "label": "$30,001 - $35,000"
    },
    {
        "value": "37500",
        "label": "$35,001 - $40,000"
    },
    {
        "value": "42500",
        "label": "$40,001 - $45,000"
    },
    {
        "value": "47500",
        "label": "$45,001 - $50,000"
    },
    {
        "value": "52500",
        "label": "$50,001 - $55,000"
    },
    {
        "value": "57500",
        "label": "$55,001 - $60,000"
    },
    {
        "value": "62500",
        "label": "$60,001 - $65,000"
    },
    {
        "value": "67500",
        "label": "$65,001 - $70,000"
    },
    {
        "value": "72500",
        "label": "$70,001 - $75,000"
    },
    {
        "value": "77500",
        "label": "$75,001 - $80,000"
    },
    {
        "value": "82500",
        "label": "$80,001 - $85,000"
    },
    {
        "value": "87500",
        "label": "$85,001 - $90,000"
    },
    {
        "value": "92500",
        "label": "$90,001 - $95,000"
    },
    {
        "value": "97500",
        "label": "$95,001 - $100,000"
    },
    {
        "value": "102500",
        "label": "$100,001 - $105,000"
    },
    {
        "value": "107500",
        "label": "$105,001 - $110,000"
    },
    {
        "value": "112500",
        "label": "$110,001 - $115,000"
    },
    {
        "value": "117500",
        "label": "$115,001 - $120,000"
    },
    {
        "value": "122500",
        "label": "$120,001 - $125,000"
    },
    {
        "value": "127500",
        "label": "$125,001 - $130,000"
    },
    {
        "value": "132500",
        "label": "$130,001 - $135,000"
    },
    {
        "value": "137500",
        "label": "$135,001 - $140,000"
    },
    {
        "value": "142500",
        "label": "$140,001 - $145,000"
    },
    {
        "value": "147500",
        "label": "$145,001 - $150,000"
    },
    {
        "value": "152500",
        "label": "$150,001 - $155,000"
    },
    {
        "value": "157500",
        "label": "$155,001 - $160,000"
    },
    {
        "value": "162500",
        "label": "$160,001 - $165,000"
    },
    {
        "value": "167500",
        "label": "$165,001 - $170,000"
    },
    {
        "value": "172500",
        "label": "$170,001 - $175,000"
    },
    {
        "value": "177500",
        "label": "$175,001 - $180,000"
    },
    {
        "value": "182500",
        "label": "$180,001 - $185,000"
    },
    {
        "value": "187500",
        "label": "$185,001 - $190,000"
    },
    {
        "value": "192500",
        "label": "$190,001 - $195,000"
    },
    {
        "value": "197500",
        "label": "$195,001 - $200,000"
    },
    {
        "value": "205000",
        "label": "$200,001 - $210,000"
    },
    {
        "value": "215000",
        "label": "$210,001 - $220,000"
    },
    {
        "value": "225000",
        "label": "$220,001 - $230,000"
    },
    {
        "value": "235000",
        "label": "$230,001 - $240,000"
    },
    {
        "value": "245000",
        "label": "$240,001 - $250,000"
    },
    {
        "value": "255000",
        "label": "$250,001 - $260,000"
    },
    {
        "value": "265000",
        "label": "$260,001 - $270,000"
    },
    {
        "value": "275000",
        "label": "$270,001 - $280,000"
    },
    {
        "value": "285000",
        "label": "$280,001 - $290,000"
    },
    {
        "value": "295000",
        "label": "$290,001 - $300,000"
    },
    {
        "value": "305000",
        "label": "$300,001 - $310,000"
    },
    {
        "value": "315000",
        "label": "$310,001 - $320,000"
    },
    {
        "value": "325000",
        "label": "$320,001 - $330,000"
    },
    {
        "value": "335000",
        "label": "$330,001 - $340,000"
    },
    {
        "value": "345000",
        "label": "$340,001 - $350,000"
    },
    {
        "value": "355000",
        "label": "$350,001 - $360,000"
    },
    {
        "value": "365000",
        "label": "$360,001 - $370,000"
    },
    {
        "value": "375000",
        "label": "$370,001 - $380,000"
    },
    {
        "value": "385000",
        "label": "$380,001 - $390,000"
    },
    {
        "value": "395000",
        "label": "$390,001 - $400,000"
    },
    {
        "value": "405000",
        "label": "$400,001 - $410,000"
    },
    {
        "value": "415000",
        "label": "$410,001 - $420,000"
    },
    {
        "value": "425000",
        "label": "$420,001 - $430,000"
    },
    {
        "value": "435000",
        "label": "$430,001 - $440,000"
    },
    {
        "value": "445000",
        "label": "$440,001 - $450,000"
    },
    {
        "value": "455000",
        "label": "$450,001 - $460,000"
    },
    {
        "value": "465000",
        "label": "$460,001 - $470,000"
    },
    {
        "value": "475000",
        "label": "$470,001 - $480,000"
    },
    {
        "value": "485000",
        "label": "$480,001 - $490,000"
    },
    {
        "value": "495000",
        "label": "$490,001 - $500,000"
    },
    {
        "value": "510000",
        "@selected": "selected",
        "label": "$500,001 - $520,000"
    },
    {
        "value": "530000",
        "label": "$520,001 - $540,000"
    },
    {
        "value": "550000",
        "label": "$540,001 - $560,000"
    },
    {
        "value": "570000",
        "label": "$560,001 - $580,000"
    },
    {
        "value": "590000",
        "label": "$580,001 - $600,000"
    },
    {
        "value": "610000",
        "label": "$600,001 - $620,000"
    },
    {
        "value": "630000",
        "label": "$620,001 - $640,000"
    },
    {
        "value": "650000",
        "label": "$640,001 - $660,000"
    },
    {
        "value": "670000",
        "label": "$660,001 - $680,000"
    },
    {
        "value": "690000",
        "label": "$680,001 - $700,000"
    },
    {
        "value": "710000",
        "label": "$700,001 - $720,000"
    },
    {
        "value": "730000",
        "label": "$720,001 - $740,000"
    },
    {
        "value": "750000",
        "label": "$740,001 - $760,000"
    },
    {
        "value": "770000",
        "label": "$760,001 - $780,000"
    },
    {
        "value": "790000",
        "label": "$780,001 - $800,000"
    },
    {
        "value": "810000",
        "label": "$800,001 - $820,000"
    },
    {
        "value": "830000",
        "label": "$820,001 - $840,000"
    },
    {
        "value": "850000",
        "label": "$840,001 - $860,000"
    },
    {
        "value": "870000",
        "label": "$860,001 - $880,000"
    },
    {
        "value": "890000",
        "label": "$880,001 - $900,000"
    },
    {
        "value": "910000",
        "label": "$900,001 - $920,000"
    },
    {
        "value": "930000",
        "label": "$920,001 - $940,000"
    },
    {
        "value": "950000",
        "label": "$940,001 - $960,000"
    },
    {
        "value": "970000",
        "label": "$960,001 - $980,000"
    },
    {
        "value": "990000",
        "label": "$980,001 - $1,000,000"
    },
    {
        "value": "1050000",
        "label": "$1,000,000 - $1,100,000"
    },
    {
        "value": "1150000",
        "label": "$1,100,000 - $1,200,000"
    },
    {
        "value": "1250000",
        "label": "$1,200,000 - $1,300,000"
    },
    {
        "value": "1350000",
        "label": "$1,300,000 - $1,400,000"
    },
    {
        "value": "1450000",
        "label": "$1,400,000 - $1,500,000"
    },
    {
        "value": "1550000",
        "label": "$1,500,000 - $1,600,000"
    },
    {
        "value": "1650000",
        "label": "$1,600,000 - $1,700,000"
    },
    {
        "value": "1750000",
        "label": "$1,700,000 - $1,800,000"
    },
    {
        "value": "1850000",
        "label": "$1,800,000 - $1,900,000"
    },
    {
        "value": "1950000",
        "label": "$1,900,000 - $2,000,000"
    },
    {
        "value": "2050000",
        "label": "$2,000,000 - $2,100,000"
    },
    {
        "value": "2150000",
        "label": "$2,100,000 - $2,200,000"
    },
    {
        "value": "2250000",
        "label": "$2,200,000 - $2,300,000"
    },
    {
        "value": "2350000",
        "label": "$2,300,000 - $2,400,000"
    },
    {
        "value": "2450000",
        "label": "$2,400,000 - $2,500,000"
    },
    {
        "value": "2550000",
        "label": "$2,500,000 - $2,600,000"
    },
    {
        "value": "2650000",
        "label": "$2,600,000 - $2,700,000"
    },
    {
        "value": "2750000",
        "label": "$2,700,000 - $2,800,000"
    },
    {
        "value": "2850000",
        "label": "$2,800,000 - $2,900,000"
    },
    {
        "value": "2950000",
        "label": "$2,900,000 - $3,000,000"
    },
    {
        "value": "3050000",
        "label": "$3,000,000 - $3,100,000"
    },
    {
        "value": "3150000",
        "label": "$3,100,000 - $3,200,000"
    },
    {
        "value": "3250000",
        "label": "$3,200,000 - $3,300,000"
    },
    {
        "value": "3350000",
        "label": "$3,300,000 - $3,400,000"
    },
    {
        "value": "3450000",
        "label": "$3,400,000 - $3,500,000"
    },
    {
        "value": "3550000",
        "label": "$3,500,000 - $3,600,000"
    },
    {
        "value": "3650000",
        "label": "$3,600,000 - $3,700,000"
    },
    {
        "value": "3750000",
        "label": "$3,700,000 - $3,800,000"
    },
    {
        "value": "3850000",
        "label": "$3,800,000 - $3,900,000"
    },
    {
        "value": "3950000",
        "label": "$3,900,000 - $4,000,000"
    },
    {
        "value": "4050000",
        "label": "$4,000,000 - $4,100,000"
    },
    {
        "value": "4150000",
        "label": "$4,100,000 - $4,200,000"
    },
    {
        "value": "4250000",
        "label": "$4,200,000 - $4,300,000"
    },
    {
        "value": "4350000",
        "label": "$4,300,000 - $4,400,000"
    },
    {
        "value": "4450000",
        "label": "$4,400,000 - $4,500,000"
    },
    {
        "value": "4550000",
        "label": "$4,500,000 - $4,600,000"
    },
    {
        "value": "4650000",
        "label": "$4,600,000 - $4,700,000"
    },
    {
        "value": "4750000",
        "label": "$4,700,000 - $4,800,000"
    },
    {
        "value": "4850000",
        "label": "$4,800,000 - $4,900,000"
    },
    {
        "value": "4950000",
        "label": "$4,900,000 - $5,000,000"
    },
    {
        "value": "5000000",
        "label": "$5,000,000+"
    }
]