// eslint-disable-next-line import/prefer-default-export
export const monthlyPayment =  [
    {
        "value": "200",
        "label": "Less than $200"
    },
    {
        "value": "250",
        "label": "$201 - $300"
    },
    {
        "value": "350",
        "label": "$301 - $400"
    },
    {
        "value": "450",
        "label": "$401 - $500"
    },
    {
        "value": "550",
        "label": "$501 - $600"
    },
    {
        "value": "650",
        "label": "$601 - $700"
    },
    {
        "value": "750",
        "label": "$701 - $800"
    },
    {
        "value": "850",
        "label": "$801 - $900"
    },
    {
        "value": "950",
        "label": "$901 - $1,000"
    },
    {
        "value": "1050",
        "label": "$1,001 - $1,100"
    },
    {
        "value": "1150",
        "label": "$1,101 - $1,200"
    },
    {
        "value": "1250",
        "label": "$1,201 - $1,300"
    },
    {
        "value": "1350",
        "label": "$1,301 - $1,400"
    },
    {
        "value": "1450",
        "label": "$1,401 - $1,500"
    },
    {
        "value": "1550",
        "label": "$1,501 - $1,600"
    },
    {
        "value": "1650",
        "label": "$1,601 - $1,700"
    },
    {
        "value": "1750",
        "label": "$1,701 - $1,800"
    },
    {
        "value": "1850",
        "label": "$1,801 - $1,900"
    },
    {
        "value": "1950",
        "label": "$1,901 - $2,000"
    },
    {
        "value": "2050",
        "label": "$2,001 - $2,100"
    },
    {
        "value": "2150",
        "label": "$2,101 - $2,200"
    },
    {
        "value": "2250",
        "label": "$2,201 - $2,300"
    },
    {
        "value": "2350",
        "label": "$2,301 - $2,400"
    },
    {
        "value": "2450",
        "label": "$2,401 - $2,500"
    },
    {
        "value": "2550",
        "label": "$2,501 - $2,600"
    },
    {
        "value": "2650",
        "label": "$2,601 - $2,700"
    },
    {
        "value": "2750",
        "label": "$2,701 - $2,800"
    },
    {
        "value": "2850",
        "label": "$2,801 - $2,900"
    },
    {
        "value": "2950",
        "label": "$2,901 - $3,000"
    },
    {
        "value": "3050",
        "label": "$3,000 - $3,100"
    },
    {
        "value": "3150",
        "label": "$3,100 - $3,200"
    },
    {
        "value": "3250",
        "label": "$3,200 - $3,300"
    },
    {
        "value": "3350",
        "label": "$3,300 - $3,400"
    },
    {
        "value": "3450",
        "label": "$3,400 - $3,500"
    },
    {
        "value": "3550",
        "label": "$3,500 - $3,600"
    },
    {
        "value": "3650",
        "label": "$3,600 - $3,700"
    },
    {
        "value": "3750",
        "label": "$3,700 - $3,800"
    },
    {
        "value": "3850",
        "label": "$3,800 - $3,900"
    },
    {
        "value": "3950",
        "label": "$3,900 - $4,000"
    },
    {
        "value": "4050",
        "label": "$4,000 - $4,100"
    },
    {
        "value": "4150",
        "label": "$4,100 - $4,200"
    },
    {
        "value": "4250",
        "label": "$4,200 - $4,300"
    },
    {
        "value": "4350",
        "label": "$4,300 - $4,400"
    },
    {
        "value": "4450",
        "label": "$4,400 - $4,500"
    },
    {
        "value": "4550",
        "label": "$4,500 - $4,600"
    },
    {
        "value": "4650",
        "label": "$4,600 - $4,700"
    },
    {
        "value": "4750",
        "label": "$4,700 - $4,800"
    },
    {
        "value": "4850",
        "label": "$4,800 - $4,900"
    },
    {
        "value": "4950",
        "label": "$4,900 - $5,000"
    },
    {
        "value": "5050",
        "label": "$5,000 - $5,100"
    },
    {
        "value": "5150",
        "label": "$5,100 - $5,200"
    },
    {
        "value": "5250",
        "label": "$5,200 - $5,300"
    },
    {
        "value": "5350",
        "label": "$5,300 - $5,400"
    },
    {
        "value": "5450",
        "label": "$5,400 - $5,500"
    },
    {
        "value": "5550",
        "label": "$5,500 - $5,600"
    },
    {
        "value": "5650",
        "label": "$5,600 - $5,700"
    },
    {
        "value": "5750",
        "label": "$5,700 - $5,800"
    },
    {
        "value": "5850",
        "label": "$5,800 - $5,900"
    },
    {
        "value": "5950",
        "label": "$5,900 - $6,000"
    },
    {
        "value": "6001",
        "label": "More than $6,000"
    }
];

