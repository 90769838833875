// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    MORTGAGE_TABLE: 'mortgage_table',
    MORTGAGE_TABLE_BODY: 'mortgage_table_head',
    MORTGAGE_TABLE_HEAD: 'mortgage_table_body',
    MORE_INFO_ROW: 'moreInfoRow',
    MORTGAGE_TABLE_TITLE_CONTAINER: 'mortgage_table_title_container',
    MORTGAGE_TABLE_DISCLOSURE: 'mortgage_table_disclosure',
};

export const defaultValues = {
    MORTGAGE_TABLE_TITLE: 'Results:',
};

export const defaultAttributes = {
    status: 'SUCCESS',
    message: null,
    containsDefaultedRates: 'ALL',
    disregardCashout: false,
    calcInfoMap: {
        n30Yr: {
            newMonthlyPayment: 35,
            ratesInfo: {
                apr: 3.997,
                interestRate: 3.75,
            },
            totalInterestSavings: -4900,
            monthlyPaymentSavings: 515,
            cashOut: 0,
        },
        n5YrARM: {
            newMonthlyPayment: 34,
            ratesInfo: { apr: 4.402, interestRate: 3.5 },
            totalInterestSavings: '???',
            monthlyPaymentSavings: 516,
            cashOut: 0,
        },
        CashOut: {
            newMonthlyPayment: 1890,
            ratesInfo: { apr: 3.997, interestRate: 3.75 },
            totalInterestSavings: -672700,
            monthlyPaymentSavings: -1340,
            cashOut: 400500,
        },
        n15Yr: {
            newMonthlyPayment: 53,
            ratesInfo: { apr: 3.683, interestRate: 3.25 },
            totalInterestSavings: -1840,
            monthlyPaymentSavings: 497,
            cashOut: 0,
        },
    },
};

export const defaultAttributes2 = {
    status: 'SUCCESS',
    message: null,
    containsDefaultedRates: 'ALL',
    disregardCashout: false,
    calcInfoMap: {
        n30Yr: {
            newMonthlyPayment: 35,
            ratesInfo: {
                apr: 3.997,
                interestRate: 3.75,
            },
            totalInterestSavings: -4900,
            monthlyPaymentSavings: 515,
            cashOut: 0,
        },
        n5YrARM: {
            newMonthlyPayment: 34,
            ratesInfo: { apr: 4.402, interestRate: 3.5 },
            totalInterestSavings: 0,
            monthlyPaymentSavings: 516,
            cashOut: 0,
        },
        CashOut: {
            newMonthlyPayment: 1890,
            ratesInfo: { apr: 3.997, interestRate: 3.75 },
            totalInterestSavings: -672700,
            monthlyPaymentSavings: -1340,
            cashOut: 400500,
        },
        n15Yr: {
            newMonthlyPayment: 53,
            ratesInfo: { apr: 3.683, interestRate: 3.25 },
            totalInterestSavings: -1840,
            monthlyPaymentSavings: 497,
            cashOut: 0,
        },
    },
};
