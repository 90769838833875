import DOMPurify from 'isomorphic-dompurify';
import { callback as anchortarget } from './anchortarget';

const arrayCallbacks = [anchortarget];

const addHookafterSanitizeAttributes = () =>
    arrayCallbacks.forEach((aftersaCallback) => 
    {
        DOMPurify.addHook('afterSanitizeAttributes', aftersaCallback);
    });

export default addHookafterSanitizeAttributes;
