import React from 'react';
import PropTypes from 'prop-types';

// Components
import CheckBox from '../Checkbox';
import DropDown from '../Dropdown';
import Input from '../Input';
import BlockButton from '../BlockButton';
import BackButton from '../BackButton';

import {testAttributes} from './Constants';
import * as CSS from './FormGenerator.module.scss';

const FormGenerator = ({
    register,
    errors,
    properties,
    action,
    step, 
    btnDisabled,
    attributes,
    controller,
    control,
}) => 
{
    const getInput = (property, index) => 
    {
        switch (property.type) 
        {
            case "input":
            case "text":
                return (
                    <Input
                        key={`input-${index}`}
                        {...property}
                        {...attributes}
                        controller={controller}
                        errors={errors}
                        action={action}
                        control={control}
                    />
                );
            case "number":
                return (
                    <Input
                        key={`input-${index}`}
                        {...property}
                        {...attributes}
                        controller={controller}
                        errors={errors}
                        action={action}
                        control={control}
                    />
                );
            case "select":
                return (
                    <DropDown
                        key={`select-${index}`}
                        {...property}
                        register={register}
                        errors={errors}
                        action={action}
                        controller={controller}
                        control={control}
                    />
                );
            case "button":
                return (
                    <BlockButton 
                        key={`btn-${index}`} 
                        {...property} 
                        disabled={btnDisabled} 
                        onClick={action.handleNextStep}
                    />
                );
            case "checkbox":
                return (
                    <CheckBox
                        key={`btn-${index}`}
                        {...property}
                        register={register}
                        errors={errors}
                    />
                );
            default:
                return null;
        }
    };

    const components = properties.map((property, index) =>
        getInput(property, index)
    );
    
    return (
        <div className={CSS.formGeneratorContainer} data-test={testAttributes.MORTGAGE_FORMGENERATOR_CONTAINER}>
            {components}
            <div className="backBtn">
                {step > 0 && (
                    <div>
                        <BackButton onClick={action.handleGoBackToPreviousStep} />
                    </div>
                )}
            </div>
        </div>
    );
}

FormGenerator.propTypes = {
    /** Function to register input in react hook form */
    register: PropTypes.func.isRequired,
    /** Object with validation errors */
    errors: PropTypes.objectOf(PropTypes.object),
    /** Object with attributes of form element  */
    attributes: PropTypes.objectOf(PropTypes.object),
    /** Array of Object with  inputs to render */
    properties: PropTypes.arrayOf(PropTypes.object),
    /** Action function to handle BackButton  */
    action: PropTypes.objectOf(PropTypes.any).isRequired,
    /** Current step in form */
    step: PropTypes.number.isRequired,
    /** Button status (disabled: true, false) */
    btnDisabled: PropTypes.bool.isRequired,
    /** contains methods for registering components */
    control: PropTypes.objectOf(PropTypes.any),
    /** Wrapper component for controlled inputs */
    controller: PropTypes.func.isRequired,
}

FormGenerator.defaultProps = {
    attributes: {},
    errors: {},
    properties: [],
    control: {},
};

export default FormGenerator;
