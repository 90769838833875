import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes, defaultAttributes } from './Constants';
import * as CSS from './DebToIncome.module.scss';

const Indicator = ({ value }) => (
    <div
        className={CSS.debContainer}
        data-test={testAttributes.MORTGAGE_DEPT_CONTAINER}
    >
        {value === 0 ? (
            <span
                className={CSS.title}
                data-test={testAttributes.MORTGAGE_DEPT_ERROR}
            >
                {defaultAttributes.error}
            </span>
        ) : (
            <>
                <span
                    className={CSS.title}
                    data-test={testAttributes.MORTGAGE_DEPT_TITLE}
                >
                    {defaultAttributes.title}
                </span>
                <span
                    className={CSS.value}
                    data-test={testAttributes.MORTGAGE_DEPT_VALUE}
                >
                    {value}
                </span>
            </>
        )}
    </div>
);

Indicator.propTypes = {
    /** label value for RATIO  */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Indicator.defaultProps = {
    value: '0',
};

export default Indicator;
