/**
 * DOMPurify usage and hooks: https://github.com/cure53/DOMPurify/blob/main/demos/README.md
 */

import DOMPurify from 'isomorphic-dompurify';
import allHookExceptions from './hooks';
import mergeExceptions from './mergeExceptions';

const purifyExceptions = [allHookExceptions];

let allExceptions = {};

purifyExceptions.forEach((purifyException) => 
{
    allExceptions = mergeExceptions(purifyException, allExceptions);
});

const sanitizeHTML = (htmlString) => DOMPurify.sanitize(htmlString, allExceptions);

export default sanitizeHTML;
