import React from 'react';
import PropTypes from 'prop-types';

import { testAttributes, CHECKBOX_DEFAULT_VALUE } from './Constants';
import * as CSS from './Checkbox.module.scss';

const CheckBox = ({ validate, errors, name, register, placeholder, value }) =>
{
    const hasValue = ( value !== CHECKBOX_DEFAULT_VALUE );
    const id = `${name}${ hasValue ? `-${value}` : ""}`;
    const dataTest = hasValue ? `${testAttributes.MORTGAGE_CHECKBOX}${value}` : `${testAttributes.MORTGAGE_CHECKBOX}${name}`;
    return (
        <>
            <label data-test={testAttributes.MORTGAGE_CHECKBOX_CONTAINER} htmlFor={name} className={CSS.checkBoxContainer}>
                <input type="checkbox" {...register(name, validate)} id={id} data-test={dataTest} value={value} />
                {placeholder}
            </label>
            {errors[name] && <span className="error">{errors[name].message}</span>}
        </>
    )
}

CheckBox.propTypes = {
    /** Object with validate rules */
    validate: PropTypes.objectOf(PropTypes.any), 
    /** object with validation errors */
    errors: PropTypes.objectOf(PropTypes.object),
    /** Checkbox name */  
    name: PropTypes.string.isRequired,
    /** Function to register input in react hook form */
    register: PropTypes.func.isRequired, 
    /** Checkbox placeholder or label */
    placeholder: PropTypes.string.isRequired,
    /** checkbox input value (defaults to 'on') */
    value: PropTypes.string
};

CheckBox.defaultProps = {
    validate: {},
    errors: {},
    value: CHECKBOX_DEFAULT_VALUE
};

export default CheckBox;
