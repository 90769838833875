import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes, defaultAttributes } from './Constants';

import { mortgageBlockButton, buttonIconImg } from "./BlockButton.module.scss"

const iconMarkup = (icon) => 
{
    if ( !icon ) return null;
    if ( typeof icon === 'string' ) return <span className={buttonIconImg}><img alt="icon" src={icon} /></span>
    return <span className={buttonIconImg}>{icon}</span>
}

const BlockButton = ({
    label,
    disabled,
    submit,
    onClick,
    icon
}) => (
    <button
        className={mortgageBlockButton}
        type={submit ? 'submit' : 'button'}
        onClick={() => !submit && onClick()}
        data-test={testAttributes.MORTGAGE_BLOCK_BUTTON}
        disabled={disabled}
    >
        { iconMarkup(icon) }
        <span>{label}</span>
    </button>
);

BlockButton.propTypes = {
    /** Block button; label default text continue  */
    label: PropTypes.oneOfType([PropTypes.string]),
    /** Block button; status default disabled: false */
    disabled: PropTypes.bool,
    /** Block button; type (Button | Submit)  */
    submit: PropTypes.bool.isRequired,
    /** Block button; onClick event */
    onClick:  PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    /** Icon - null (default), string (img src), or JSX */
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
        PropTypes.oneOf([null])
    ])
};

BlockButton.defaultProps = {
    label: defaultAttributes.BUTTON,
    disabled: false,
    onClick: false,
    icon: null
};

export default BlockButton;
