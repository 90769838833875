import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes, defaultAttributes } from './Constants';

import {mortgageBackButton}  from "./BackButton.module.scss"

const BackButton = ({
    onClick,
}) => (
    <button
        type="button"
        className={mortgageBackButton}
        onClick={onClick}
        data-test={testAttributes.MORTGAGE_BACK_BUTTON}
    >
        <span>{defaultAttributes.LABEL}</span>
    </button>
);

BackButton.propTypes = {
    /** Back button; event to go back in step form  */
    onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

BackButton.defaultProps = {
    onClick: false,
};

export default BackButton;
