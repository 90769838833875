import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes, defaultValues } from './Constants';
import {
    titleContainer,
    tableContent,
    checkmark,
    xmark,
    disclosureDiv,
    negative
} from './MortgageCalculator.module.scss';
import { formatMoney } from '../../../../utilities/formatMoney/index';

const MortgageCalculator = ({
    n30Yr,
    n15Yr,
    n5YrARM,
    moreInfoRow,
    title,
    disclosure,
}) => 
{
    const renderValue = (value) => 
        <span className={parseFloat(value) < 0 ? negative : ''}>{formatMoney(value)}</span>
    return (
        <>
            <div
                className={titleContainer}
                data-test={testAttributes.MORTGAGE_TABLE_TITLE_CONTAINER}
            >
                <h2>{title}</h2>
            </div>
            <div className={tableContent}>
                <table data-test={testAttributes.MORTGAGE_TABLE}>
                    <thead data-test={testAttributes.MORTGAGE_TABLE_HEAD}>
                        <tr>
                            <td>Loan Type</td>
                            <td>30 Yr Fixed</td>
                            <td>15 Yr Fixed</td>
                            <td>5/1 ARM</td>
                        </tr>
                    </thead>
                    <tbody data-test={testAttributes.MORTGAGE_TABLE_BODY}>
                        <tr>
                            <td>Monthly Payment</td>
                            <td>{n30Yr && renderValue(n30Yr.monthlyPayment)} </td>
                            <td>{n15Yr && renderValue(n15Yr.monthlyPayment)} </td>
                            <td>
                                {n5YrARM && renderValue(n5YrARM.monthlyPayment)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                            APR <br /> (Rate)
                            </td>
                            <td>
                                {n30Yr.ratesInfo.apr}% <br />(
                                {n30Yr.ratesInfo.interestRate}%)
                            </td>
                            <td>
                                {n15Yr.ratesInfo.apr}% <br />(
                                {n15Yr.ratesInfo.interestRate}%)
                            </td>
                            <td>
                                {n5YrARM.ratesInfo.apr}% <br />(
                                {n5YrARM.ratesInfo.interestRate}%)
                            </td>
                        </tr>
                        <tr>
                            <td>Total Interest</td>
                            <td>{n30Yr && renderValue(n30Yr.totalInterest)} </td>
                            <td>{n15Yr && renderValue(n15Yr.totalInterest)} </td>
                            <td>???</td>
                        </tr>

                        <tr>
                            <td>Term</td>
                            <td>30 Years</td>
                            <td>15 Years</td>
                            <td>30 Years</td>
                        </tr>
                        <tr>
                            <td>Lower Payment?</td>
                            <td>
                                <span className={checkmark} />
                            </td>
                            <td>
                                <span className={xmark} />
                            </td>
                            <td>
                                <span className={checkmark} />
                            </td>
                        </tr>
                        <tr>
                            <td>Lower Rate?</td>
                            <td>
                                <span className={xmark} />
                            </td>
                            <td>
                                <span className={checkmark} />
                            </td>
                            <td>
                                <span className={checkmark} />
                            </td>
                        </tr>
                        <tr>
                            <td>Pay Off Faster?</td>
                            <td>
                                <span className={xmark} />
                            </td>
                            <td>
                                <span className={checkmark} />
                            </td>
                            <td>
                                <span className={xmark} />
                            </td>
                        </tr>
                        <tr>
                            <td>Pay Less Interest?</td>
                            <td>
                                <span className={xmark} />
                            </td>
                            <td>
                                <span className={checkmark} />
                            </td>
                            <td>
                                <span className={xmark} />
                            </td>
                        </tr>
                        {moreInfoRow && Object.keys(moreInfoRow).length ? (
                        <tr data-test={testAttributes.MORE_INFO_ROW}>
                            <td />
                            <td>{moreInfoRow.n30Yr || null}</td>
                            <td>{moreInfoRow.n15Yr || null}</td>
                            <td>{moreInfoRow.n5YrARM || null}</td>
                        </tr>
                        ) : null}
                    </tbody>
                </table>
                {disclosure ? (
                <div
                    className={disclosureDiv}
                    data-test={testAttributes.MORTGAGE_TABLE_DISCLOSURE}
                >
                    <p>
                        Note: The calculator results do not include all the
                        taxes, insurance premiums, and fees that you may be
                        required to pay. The monthly payment is based on
                        self-reported information and meant to serve as an
                        estimate only. Your actual payment amount will vary.
                    </p>
                </div>
                ) : (
                ''
                )}
            </div>
        </>
    )
};

MortgageCalculator.propTypes = {
    /** 30 yr fixed props */
    n30Yr: PropTypes.objectOf(Object).isRequired,
    /** 15 yr fixed props */
    n15Yr: PropTypes.objectOf(Object).isRequired,
    /** 5/1 ARM props */
    n5YrARM: PropTypes.objectOf(Object).isRequired,
    /** data for "read more" row, optional */
    moreInfoRow: PropTypes.objectOf(Object),
    /** title for table, optional, default "result" */
    title: PropTypes.string,
    /** Bool: Show disclosure content when table renders */
    disclosure: PropTypes.bool,
};

MortgageCalculator.defaultProps = {
    moreInfoRow: {},
    title: defaultValues.MORTGAGE_TABLE_TITLE,
    disclosure: false,
};

export default MortgageCalculator;
