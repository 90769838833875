export const testAttributes = {
    GRID_POST_CONTAINER: 'grid_post_container',
    BLOCK_BUTTON: 'block_button',
};

// eslint-disable-next-line import/prefer-default-export
export const gridParams = {
    samplePosts: [
        {
            author: 'Marlese Lessing',
            readingtime: 9,
            databaseId: 15667,
            link: 'https://dev-lmb.pantheonsite.io/mortgage/what-is-apr-2/',
            title: 'What Is APR and How Does It Work for Mortgages?',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 9,
            databaseId: 14984,
            link:
                'https://dev-lmb.pantheonsite.io/personal-loans/how-to-get-a-loan-with-bad-credit/',
            title: 'How To Get A Loan With Bad Credit',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 10,
            databaseId: 14981,
            link:
                'https://dev-lmb.pantheonsite.io/insurance/ultimate-guide-to-health-insurance/',
            title: 'Ultimate Guide to Health Insurance',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 8,
            databaseId: 14978,
            link:
                'https://dev-lmb.pantheonsite.io/personal-loans/bestegg-review/',
            title: 'BestEgg Review',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 7,
            databaseId: 14974,
            link:
                'https://dev-lmb.pantheonsite.io/personal-finance/gig-economy-101/',
            title: 'Gig Economy 101',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 10,
            databaseId: 14969,
            link:
                'https://dev-lmb.pantheonsite.io/home-services/how-to-find-a-good-contractor-for-your-home-improvement-project/',
            title:
                'How To Find A Good Contractor For Your Home Improvement Project',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 10,
            databaseId: 14968,
            link:
                'https://dev-lmb.pantheonsite.io/home-services/how-to-find-a-good-contractor-for-your-home-improvement-project/',
            title:
                'How To Find A Good Contractor For Your Home Improvement Project',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 10,
            databaseId: 14967,
            link:
                'https://dev-lmb.pantheonsite.io/home-services/how-to-find-a-good-contractor-for-your-home-improvement-project/',
            title:
                'How To Find A Good Contractor For Your Home Improvement Project',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 10,
            databaseId: 14966,
            link:
                'https://dev-lmb.pantheonsite.io/home-services/how-to-find-a-good-contractor-for-your-home-improvement-project/',
            title:
                'How To Find A Good Contractor For Your Home Improvement Project',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 10,
            databaseId: 14965,
            link:
                'https://dev-lmb.pantheonsite.io/home-services/how-to-find-a-good-contractor-for-your-home-improvement-project/',
            title:
                'How To Find A Good Contractor For Your Home Improvement Project',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 10,
            databaseId: 14964,
            link:
                'https://dev-lmb.pantheonsite.io/home-services/how-to-find-a-good-contractor-for-your-home-improvement-project/',
            title:
                'How To Find A Good Contractor For Your Home Improvement Project',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 10,
            databaseId: 14963,
            link:
                'https://dev-lmb.pantheonsite.io/home-services/how-to-find-a-good-contractor-for-your-home-improvement-project/',
            title:
                'How To Find A Good Contractor For Your Home Improvement Project',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 10,
            databaseId: 14962,
            link:
                'https://dev-lmb.pantheonsite.io/home-services/how-to-find-a-good-contractor-for-your-home-improvement-project/',
            title:
                'How To Find A Good Contractor For Your Home Improvement Project',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 10,
            databaseId: 14961,
            link:
                'https://dev-lmb.pantheonsite.io/home-services/how-to-find-a-good-contractor-for-your-home-improvement-project/',
            title:
                'How To Find A Good Contractor For Your Home Improvement Project',
        },
        {
            author: 'The LowerMyBills Team',
            readingtime: 10,
            databaseId: 14960,
            link:
                'https://dev-lmb.pantheonsite.io/home-services/how-to-find-a-good-contractor-for-your-home-improvement-project/',
            title:
                'How To Find A Good Contractor For Your Home Improvement Project',
        },
    ],
};
