import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes, defaultAttributes } from './Constants';
import NavBar from '../../simple/NavBar';
import {
    container,
    content,
    headerContent,
    headerLogo,
    contentMenu,
} from './Header.module.scss';

/**
 Component that allows displaying the header using 1 subcomponent ( NavBar  ).
 */
const Header = ({ headerMenu, logoUrl, LogoAlt, locationPath }) => (
    <header
        className={container}
        data-test={testAttributes.HEADER_MAIN_CONTAINER}
        role="banner"
        itemScope
        itemType="https://schema.org/WPHeader"
    >
        <div
            className={`${content} ${headerContent}`}
            data-test={testAttributes.HEADER_CONTENT}
        >
            <div className="logo" data-test={testAttributes.HEADER_LOGO}>
                <a
                    href={logoUrl}
                    data-test={testAttributes.HEADER_LOGO_LINK}
                    aria-label={LogoAlt}
                >
                    <div
                        data-test={testAttributes.HEADER_LOGO_IMG}
                        className={headerLogo}
                        role="img"
                        aria-label={LogoAlt}
                    />
                </a>
            </div>

            <div data-test={testAttributes.HEADER_MENU} className={contentMenu}>
                <NavBar
                    navItems={headerMenu}
                    locationPath={locationPath}
                    data-test={testAttributes.HEADER_MENU_NAVBAR}
                />
            </div>
        </div>
    </header>
);
Header.propTypes = {
    /** headerMenu, List of menu to NavBar */
    headerMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
    /** url, link to url when press the logo */
    logoUrl: PropTypes.string,
    /** Alt, alt string to logo */
    LogoAlt: PropTypes.string,
    /** Current location path which is Highlighted in navbar */
    locationPath: PropTypes.string,
};

Header.defaultProps = {
    logoUrl: defaultAttributes.url,
    LogoAlt: defaultAttributes.alt,
    locationPath: '',
};
export default Header;
