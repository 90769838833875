// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    MORTGAGE_DEPT_CONTAINER: 'mortgage_dept_container',
    MORTGAGE_DEPT_TITLE: 'mortgage_dept_title',
    MORTGAGE_DEPT_VALUE: 'mortgage_dept_value',
    MORTGAGE_DEPT_ERROR: 'mortgage_dept_error',
};

export const defaultAttributes = {
    title: 'DEBT-TO-INCOME RATIO',
    error: 'You have no income.'
}