// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    MORTGAGE_STEP_LINE: 'mortgage_step_line',
    MORTGAGE_STEP_ICON: 'mortgage_step_icon',
    MORTGAGE_STEP_INDICATOR: 'mortgage_step_indicator',
    MORTGAGE_STEP_CONTAINER: 'mortgage_step_container'
};


export const defaultAttributes = [
    {
        step: 1,  
    },  
    {
        step: 2, 
    },
    {
        step: 3,
    }, 
    {
        step: 4,
    },
    {
        step: 5,
    },  
    {
        step: 6,
    },
    {
        step: 7,
    }
]        
