import React from 'react';
import PropTypes from 'prop-types';

import { testAttributes } from './Constants';
import * as CSS from './BlockButton.module.scss';

const BlockButton = ({
    label,
    onClick,
    loading,
    buttonTestAttribute,
    loadingTestAttribute,
    containerTestAttribute,
    className
}) => (
    <div className={CSS.postGridMore} data-test={containerTestAttribute}>
        {loading ? (
            <div data-test={loadingTestAttribute}>Loading</div>
        ) : (
            <button
                type="button"
                onClick={onClick}
                data-test={buttonTestAttribute}
                className={className}
            >
                {label}
            </button>
        )}
    </div>
);

BlockButton.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string]),
    onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    loading: PropTypes.bool,
    buttonTestAttribute: PropTypes.string,
    loadingTestAttribute: PropTypes.string,
    containerTestAttribute: PropTypes.string,
    className:PropTypes.string,
};

BlockButton.defaultProps = {
    label: 'See more',
    loading: false,
    onClick: false,
    buttonTestAttribute: testAttributes.BLOCK_BUTTON,
    loadingTestAttribute: testAttributes.BOTTON_LOADING,
    containerTestAttribute: testAttributes.BUTTON_CONTAINER,
    className:"btn"
};

export default BlockButton;
