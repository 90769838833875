import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes } from './Constants';
import FooterMenu from '../../simple/FooterMenu';
import SocialLinks from '../../simple/SocialLinks';
import {
    container,
    containerBlock,
    containerRow,
    itemchild,
    socialRow,
    itemIcon,
} from './Footer.module.scss';

/**
 Component that allows displaying the footer using 2 subcomponent ( SocialLinks, FooterMenu  ).
 */
const Footer = ({ itemsMenu, socialLinks }) => (
    <footer
        className={container}
        data-test={testAttributes.FOOTER_MAIN_CONTAINER}
        role="contentinfo"
        itemScope
        itemType="https://schema.org/WPFooter"
    >
        <div className={containerBlock}>
            <div className={socialRow}>
                <SocialLinks
                    links={socialLinks}
                    addIconClass={itemIcon}
                    data-test={testAttributes.FOOTER_SOCIALROW}
                />
            </div>
            <div
                className={containerRow}
                data-test={testAttributes.FOOTER_MIDDLEROW}
            >
                <div className={itemchild}>
                    <FooterMenu
                        footerNav={itemsMenu.companyMenu}
                        typeOrder="vertical"
                        separator
                        data-test={testAttributes.FOOTER_FOOTERMENU1}
                    />
                </div>
                <div className={itemchild}>
                    <FooterMenu
                        footerNav={itemsMenu.personalMenu}
                        typeOrder="vertical"
                        separator
                        data-test={testAttributes.FOOTER_FOOTERMENU2}
                    />
                </div>
            </div>
            <div data-test={testAttributes.FOOTER_BOTTOMROW}>
                <FooterMenu
                    footerNav={itemsMenu.policyMenu}
                    typeOrder="horizontal"
                    separator={false}
                    data-test={testAttributes.FOOTER_FOOTERMENU3}
                />
            </div>
        </div>
    </footer>
);

Footer.propTypes = {
    /** itemsMenu, object with the 3 menus required */
    itemsMenu: PropTypes.objectOf(PropTypes.any),
    /** socialLinks, Links of the socials pages */
    socialLinks: PropTypes.objectOf(PropTypes.any),
};

Footer.defaultProps = {
    itemsMenu: [],
    socialLinks: [],
};
export default Footer;
