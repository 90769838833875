// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var titleContainer = "bj_dl";
export var tableContent = "bj_dr";
export var disclosureDiv = "bj_ds";
export var checkmark = "bj_dn";
export var xmark = "bj_dp";
export var negative = "bj_dt";