// Compoenents
import MortgageComparison from '../../components/mortgage/Tables/MortgageComparison';
import {mortgageComparison} from '../../utilities/mortgage';
import {defaultValues} from '../../components/mortgage/Tables/MortgageComparison/Constants';

const CHECKBOX_NAME = 'mortgageCheckbox';
const validateMultipleChecked = (value) => value.length > 1;

export const dataForm = {
    endpoint: '',
    callBack: mortgageComparison,
    resultComponent: MortgageComparison,
    showSteps: false,
    tableTitle: defaultValues.MORTGAGE_TABLE_TITLE,
    formScheme: [
        {
            step: 1,
            subTitle: '',
            filter: () => 
                // @TODO: Just for test 
                true
            ,
            properties: [
                {
                    type: 'checkbox',
                    className: '',
                    name: CHECKBOX_NAME,
                    value: 'mortgage-comparison-30-year-fixed',
                    placeholder: '30 Year Fixed',
                    validate: {
                        validate: validateMultipleChecked,
                        maxLength: 2,
                    }
                },
                {
                    type: 'checkbox',
                    className: '',
                    name: CHECKBOX_NAME,
                    value: 'mortgage-comparison-15-year-fixed',
                    placeholder: '15 Year Fixed',
                    validate: {
                        validate: validateMultipleChecked,
                        maxLength: 2,
                    }
                },
                {
                    type: 'checkbox',
                    className: '',
                    name: CHECKBOX_NAME,
                    value: 'mortgage-comparison-cash-out',
                    placeholder: 'Cash Out Refinance',
                    validate: {
                        validate: validateMultipleChecked,
                        maxLength: 2,
                    }
                },
                {
                    type: 'checkbox',
                    className: '',
                    name: CHECKBOX_NAME,
                    value: 'mortgage-comparison-5-1-arm',
                    placeholder: '5/1 Year Adjustable',
                    validate: {
                        validate: validateMultipleChecked,
                        maxLength: 2,
                    }
                },
                {
                    type: 'checkbox',
                    className: '',
                    name: CHECKBOX_NAME,
                    value: 'mortgage-comparison-fha',
                    placeholder: 'FHA Loan',
                    validate: {
                        validate: validateMultipleChecked,
                        maxLength: 2,
                    }
                },
                {
                    type: 'checkbox',
                    className: '',
                    name: CHECKBOX_NAME,
                    value: 'mortgage-comparison-va',
                    placeholder: 'VA Loan',
                    validate: {
                        validate: validateMultipleChecked,
                        maxLength: 2,
                    }
                },
                {
                    type: 'button',
                    label: 'Calculate',
                    submit: true,
                }
            ]
        }
    ]
};
  
export default dataForm;
