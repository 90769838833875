export const testAttributes = {
    LINK: 'component_linker_link',
    A: 'component_linker_anchor',
};
export const localHostnames = [
    '^www\\.lowermybills\\.com', // subdomains are not local (need www)
    '^lowermybills\\.com',
    '^\\w+\\-lmb\\.pantheonsite\\.io',
    '^lmb-seo\\.qa\\.cdm',
    '^localhost',
];
