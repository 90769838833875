// Compoenents
import RefinanceTable from '../../components/mortgage/Tables/RefinanceTable';

import {refinanceMortgage} from '../../utilities/mortgage';
import {defaultValues} from '../../components/mortgage/Tables/RefinanceTable/Constants';

import {
    monthlyPayment,
    mortgageBalance,
    homeValue,
    creditProfile,
    interestRate
} from '../data';

export const dataForm = {
    endpoint: 'refinancecalculation',
    callBack: refinanceMortgage,
    resultComponent: RefinanceTable,
    showSteps: true,
    tableTitle: defaultValues.MORTGAGE_TABLE_TITLE,
    disclosure:true,
    formScheme: [
        {
            step: 1,
            filter: () =>
                // @TODO: Just for test 
                true
            ,
            properties: [
                {
                    type: 'select',
                    label: 'Select Your Current Mortgage Balance',
                    tipText: 'OK to estimate',
                    name: 'currentmortgagebalance',
                    placeholder: 'Select One',
                    validate: {
                        required: { value: true, message: "Please select your current Mortgage Balance"}, 
                    },
                
                    data: mortgageBalance
                },
                {
                    type: 'button',
                    label: 'Continue',
                    submit: false,
                }
            ],
        },
        {
            step: 2,
            filter: () =>
                // @TODO: Just for test 
                true
            ,
            properties: [
                {
                    type: 'select',
                    label: 'Select Your Current Home Value',
                    tipText: 'OK to estimate',
                    name: 'currenthomevalue',
                    placeholder: 'Select One',
                    validate: {
                        required: { value: true, message: "Please select your current Home Value"},
                    },
                    data: homeValue
                },
                {
                    type: 'button',
                    label: 'Continue',
                    submit: false,
                }
            ],
        },
        {
            step: 3,
            filter: () => 
                // @TODO: Just for test 
                true
            ,
            properties: [
                {
                    type: 'select',
                    label: 'Select Your Credit Profile',
                    tipText: '',
                    name: 'creditscore',
                    placeholder: 'Select One',
                    validate: {
                        required: { value: true, message: "Please select your Credit Profile"},
                    },
                    data: creditProfile
                },
                {
                    type: 'button',
                    label: 'Continue',
                    submit: false,
                }
            ],
        },
        {
            step: 4,
            filter: () =>
                // @TODO: Just for test 
                true
            ,
            properties: [
                {
                    type: 'select',
                    label: 'Select Your Current Interest Rate',
                    tipText: 'OK to estimate',
                    name: 'currentinterestrate',
                    placeholder: 'Select One',
                    validate: {
                        required: { value: true, message: "Please select your current Interest Rate"},
                    },
                    data: interestRate
                },
                {
                    type: 'button',
                    label: 'Continue',
                    submit: false,
                }
            ]
        },
        {
            step: 5,
            filter: () =>
                // @TODO: Just for test 
                true
            ,
            properties: [
                {
                    type: 'select',
                    label: 'Select your current Monthly Payment',
                    tipText: '',
                    name: 'currentmonthlypayment',
                    placeholder: 'Select One',
                    validate: {
                        required: { value: true, message: "Please select your current Monthly Payment"},
                    },
                    data: monthlyPayment
                },
                {
                    type: 'button',
                    label: 'Continue',
                    submit: false,
                }
            ]
        },
        {
            step: 6,
            filter: () =>
                // @TODO: Just for test 
                true
            ,
            properties: [
                {
                    type: 'text',
                    label: 'Enter Your ZIP Code',
                    tipText: '',
                    name: 'zipcode',
                    placeholder: '00000',
                    inputPattern: /^[0-9\b]+$/,
                    validate: {
                        pattern: {
                            value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                            message: 'Please enter a valid ZIP code',
                        },
                        required: { 
                            value: true, 
                            message: 'Please enter a valid ZIP code', 
                        },
                        maxLength: 5,
                    },
                    attributes: {
                        maxLength: 5,
                        inputMode: 'numeric',
                    },
                },
                {
                    type: 'button',
                    label: 'Calculate',
                    submit: true,
                }
            ],
        }
    ]
};
  
export default dataForm;
