// eslint-disable-next-line import/prefer-default-export
export const creditProfile =  [
    {
        "value": "740",
        "label": "Excellent (720 or above)"
    },
    {
        "value": "670",
        "label": "Good (620 - 719)"
    },
    {
        "value": "599",
        "label": "Fair (580-619)"
    },
    {
        "value": "559",
        "label": "Needs Improvement (540-579)"
    },
    {
        "value": "500",
        "label": "Poor (539 or lower)"
    }
]