// Compoenents
import MortgageCalculator from '../../components/mortgage/Tables/MortgageCalculator';

import {mortgageCalculator} from '../../utilities/mortgage';
import {defaultValues} from '../../components/mortgage/Tables/MortgageCalculator/Constants';

import {
    homeValue,
    creditProfile,
} from '../data';
  
export const dataForm = {
    endpoint: 'mortgagecalculation',
    callBack: mortgageCalculator,
    resultComponent: MortgageCalculator,
    showSteps: false,
    tableTitle: defaultValues.MORTGAGE_TABLE_TITLE,
    disclosure:true,
    formScheme: [
        {
            step: 1,
            filter: () => 
                // @TODO: Just for test
                true
            ,
            properties: [
                {
                    type: 'select',
                    label: 'Loan Amount',
                    tipText: '',
                    name: 'loanamount',
                    placeholder: 'Select One',
                    validate: {
                        required: { 
                            value: true, message: 'Please select your Loan Amount',
                        },
                    },
                    data: homeValue
                },
                {
                    type: 'select',
                    label: 'Credit Profile',
                    tipText: '',
                    name: 'creditscore',
                    placeholder: 'Select One',
                    validate: {
                        required: { 
                            value: true, message: 'Please select your Credit Profile'
                        },
                    },
                    data: creditProfile
                },
                {
                    type: 'text',
                    label: 'ZIP Code',
                    tipText: '',
                    name: 'zipcode',
                    required: true,
                    placeholder: '00000',
                    inputPattern: /^[0-9\b]+$/,
                    validate: {
                        pattern: {
                            value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                            message: 'Please enter a valid ZIP code'
                        },
                        required: { 
                            value: true, 
                            message: 'Please enter a valid ZIP code',
                        },
                        maxLength: 5,
                    },
                    attributes: {
                        maxLength: 5,
                        inputMode: 'numeric',
                    },
                },
                {
                    type: 'button',
                    label: 'Calculate',
                    submit: true,
                }
            ]
        }
    ]
};
  
export default dataForm;
