// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var titleContainer = "bg_dl";
export var tableContainer = "bg_dm";
export var checkmark = "bg_dn";
export var xmark = "bg_dp";
export var tableTitles = "bg_dq";
export var tableContent = "bg_dr";