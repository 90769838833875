const addRelAttribute = (props) => 
{
    const addRel = [];
    if (!props.rel?.includes('noopener')) addRel.push('noopener');
    if (!props.rel?.includes('noreferrer')) addRel.push('noreferrer');
    if (props.rel?.length) addRel.push(props.rel);
    // eslint-disable-next-line no-param-reassign
    if (addRel.length) props.rel = addRel.join(' ');
};
export default addRelAttribute;
