/**
 * 👋 Hey there!
 * This file is the starting point for your new WordPress/Gatsby site! 🚀
 * For more information about what this file is and does, see
 * https://www.gatsbyjs.com/docs/gatsby-config/
 *
 */
const buildEnvs = require('./_envs.json');

const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'local';
const GATSBY_SITE_URL =
    process.env.GATSBY_SITE_URL ||
    (buildEnvs[activeEnv]
        ? buildEnvs[activeEnv].GATSBY_SITE_URL
        : 'http://localhost:8000');
const WPGRAPHQL_URL =
    process.env.WPGRAPHQL_URL ||
    (buildEnvs[activeEnv]
        ? buildEnvs[activeEnv].WPGRAPHQL_URL
        : 'http://localhost/graphql');
const ASSET_PATH =
    process.env.ASSET_PATH ||
    (buildEnvs[activeEnv] ? buildEnvs[activeEnv].ASSET_PATH || '' : '');

const gtmAuth =
    activeEnv === 'prod' || activeEnv === 'production'
        ? undefined
        : 'BRQHpB-p88vhv0UQO5pZ0w';

module.exports = {
    assetPrefix: ASSET_PATH,
    siteMetadata: {
        siteUrl: GATSBY_SITE_URL || 'https://www.lowermybills.com',
        title: process.env.GATSBY_SITE_TITLE || 'LowerMyBills',
        version: process.env.GATSBY_APP_VERSION || '0.0.0',
    },
    flags: {
        // more info: https://github.com/gatsbyjs/gatsby/discussions/32389
        PARALLEL_QUERY_RUNNING: false
    },
    /**
     * Adding plugins to this array adds them to your Gatsby site.
     *
     * Gatsby has a rich ecosystem of plugins.
     * If you need any more you can search here: https://www.gatsbyjs.com/plugins/
     */
    plugins: [

        {
            /**
             * First up is the WordPress source plugin that connects Gatsby
             * to your WordPress site.
             *
             * visit the plugin docs to learn more
             * https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-source-wordpress/README.md
             *
             */
            resolve: 'gatsby-source-wordpress',
            options: {
                debug: {
                    graphql: {
                        showQueryOnError: true,
                    },
                },
                /** @NOTE - If 'yarn start' or 'yarn start:local' time out, then adjust the following values: */
                schema: {
                    timeout: 90000, // default 30000
                    perPage: 20, // default 100
                    requestConcurrency: 8, // default 15
                    previewRequestConcurrency: 3, // default 5
                },
                // the only required plugin option for WordPress is the GraphQL url.
                url: WPGRAPHQL_URL || 'https://www.lowermybills.com/graphql',
            },
        },

        /**
         * We need this plugin so that it adds the "File.publicURL" to our site
         * It will allow us to access static url's for assets like PDF's
         *
         * See https://www.gatsbyjs.org/packages/gatsby-source-filesystem/ for more info
         */
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                name: 'assets',
                path: `${__dirname}/content/assets`,
            },
        },
        'gatsby-plugin-sass',
        {
            resolve: 'gatsby-plugin-minify-classnames',
            options: {
                dictionary:
                    'bcdfghjklmnpqrstvwxyzBCDFGHJKLMNPQRSTVWXYZ0123456789',
                // enableOnDevelopment: true,
            },
        },

        /**
         * The following two plugins are required if you want to use Gatsby image
         * See https://www.gatsbyjs.com/docs/gatsby-image/#setting-up-gatsby-image
         * if you're curious about it.
         */
        'gatsby-plugin-image',
        'gatsby-transformer-sharp',
        'gatsby-plugin-sharp',

        {
            // See https://www.gatsbyjs.com/plugins/gatsby-plugin-manifest/?=gatsby-plugin-manifest
            resolve: 'gatsby-plugin-manifest',
            options: {
                name: 'LowerMyBills.com',
                short_name: 'LowerMyBills',
                start_url: '/',
                background_color: '#ffffff',
                theme_color: '#ffffff',
                display: 'minimal-ui',
                icon: 'content/assets/LMBLogoIcon.svg',
                icons: [
                    {
                        src: '/page_assets/icons/icon-48x48.png',
                        sizes: '48x48',
                        type: 'image/png',
                    },
                    {
                        src: '/page_assets/icons/icon-72x72.png',
                        sizes: '72x72',
                        type: 'image/png',
                    },
                    {
                        src: '/page_assets/icons/icon-96x96.png',
                        sizes: '96x96',
                        type: 'image/png',
                    },
                    {
                        src: '/page_assets/icons/icon-144x144.png',
                        sizes: '144x144',
                        type: 'image/png',
                    },
                    {
                        src: '/page_assets/icons/icon-192x192.png',
                        sizes: '192x192',
                        type: 'image/png',
                    },
                    {
                        src: '/page_assets/icons/icon-256x256.png',
                        sizes: '256x256',
                        type: 'image/png',
                    },
                    {
                        src: '/page_assets/icons/icon-384x384.png',
                        sizes: '384x384',
                        type: 'image/png',
                    },
                    {
                        src: '/page_assets/icons/icon-512x512.png',
                        sizes: '512x512',
                        type: 'image/png',
                    },
                ],
            },
        },

        // See https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=gatsby-plugin-react-helmet
        'gatsby-plugin-react-helmet',

        /**
         * this (optional) plugin enables Progressive Web App + Offline functionality
         * To learn more, visit: https://gatsby.dev/offline
         */
        // `gatsby-plugin-offline`,
        {
            resolve: 'gatsby-plugin-google-tagmanager',
            options: {
                id: 'GTM-5NTS6DV',
                // Include GTM in development.
                //
                // Defaults to false meaning GTM will only be loaded in production.
                includeInDevelopment: false,

                // datalayer to be set before GTM is loaded
                // should be an object or a function that is executed in the browser
                //
                // Defaults to null
                defaultDataLayer: { platform: 'gatsby' },

                // Specify optional GTM environment details.
                gtmAuth,
                gtmPreview: 'env-30',
                // dataLayerName: 'YOUR_DATA_LAYER_NAME',

                // Name of the event that is triggered
                // on every Gatsby route change.
                //
                // Defaults to gatsby-route-change
                // routeChangeEventName: 'YOUR_ROUTE_CHANGE_EVENT_NAME',
                // Defaults to false
                enableWebVitalsTracking: true,
                // Defaults to https://www.googletagmanager.com
                // selfHostedOrigin: 'YOUR_SELF_HOSTED_ORIGIN',
            },
        },
    ],
};

/**
 * workaround:
 * currently, gatsby-plugin-manifest doesn't add assets prefix
 * This fixes, by adding icons config array, with each src prefixed
 */
const addPrefixedIcons = () => 
{
    if (ASSET_PATH && ASSET_PATH.length) 
    {
        const manifestPlugin = module.exports.plugins.find(
            (ob) => ob.resolve === 'gatsby-plugin-manifest'
        );
        if (!manifestPlugin) 
        {
            return;
        }
        const manifestIcons = [];
        [48, 72, 96, 144, 192, 256, 384, 512].forEach((dim) => 
        {
            manifestIcons.push({
                src: `${ASSET_PATH}/icons/icon-${dim}x${dim}.png`,
                sizes: `${dim}x${dim}`,
                type: 'image/png',
            });
        });
        manifestPlugin.options.icons = [...manifestIcons];
    }
};
addPrefixedIcons();
