/* eslint-disable import/prefer-default-export */
export const formatMoney = (number) => 
{
    const formated = parseFloat(number);
    const numericValue = formated.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    const isNumeric =
        typeof number === 'number' && !Number.isNaN(number)
            ? numericValue
            : '???';
    return isNumeric;
};
