// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var modalAlertContainer = "bl_dw";
export var modalAlertContent = "bl_dx";
export var animatetop = "bl_dy";
export var modalAlertHeader = "bl_dz";
export var modalAlertBody = "bl_dB";
export var modalAlertFooter = "bl_dC";