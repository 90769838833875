import getPropPath from '../../../../utilities/getPropPath';

// eslint-disable-next-line import/prefer-default-export
export const siteConstants = {
    name: 'LowerMyBills',
    description: 'Personal finance experts helping you lower your bills',
    url: 'https://www.lowermybills.com',
};
const findMatchingMeta = (nodeObs, objPath, matchValue) =>
    nodeObs.find(
        (meta) =>
            getPropPath(meta, objPath)?.toLowerCase() ===
            matchValue.toLowerCase()
    );
const findTitle = (nodeObs) =>
    nodeObs.find((meta) => meta.tagName === 'TITLE')?.textContent ||
    siteConstants.name;
// eslint-disable-next-line import/prefer-default-export
export const getMinRequiredMetas = (nodeObs) => ({
    title: {
        tagName: 'TITLE',
        textContent: findTitle(nodeObs),
    },
    charset: {
        tagName: 'META',
        attributes: {
            charset: 'utf-8',
        },
    },
    robots: {
        tagName: 'META',
        attributes: {
            name: 'robots',
            content:
                'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1',
        },
    },
    viewport: {
        tagName: 'META',
        attributes: {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0',
        },
    },
    description: {
        tagName: 'META',
        attributes: {
            name: 'description',
            content:
                findMatchingMeta(nodeObs, 'attributes.name', 'description')
                    ?.attributes?.content || siteConstants.description,
        },
    },
    'og:type': {
        tagName: 'META',
        attributes: {
            property: 'og:type',
            content: 'webpage',
        },
    },
    'og:site_name': {
        tagName: 'META',
        attributes: {
            property: 'og:site_name',
            content: siteConstants.name,
        },
    },
    'og:url': {
        tagName: 'META',
        attributes: {
            property: 'og:url',
            content: siteConstants.url,
        },
    },
    'og:title': {
        tagName: 'META',
        attributes: {
            property: 'og:title',
            content: findTitle(nodeObs),
        },
    },
    'og:description': {
        tagName: 'META',
        attributes: {
            property: 'og:description',
            content:
                findMatchingMeta(nodeObs, 'attributes.name', 'description')
                    ?.attributes?.content || siteConstants.description,
        },
    },
});

export const testNodeObs = [
    {
        tagName: 'TITLE',
        attributes: {},
        textContent: 'Auto Insurance Archives - Policy Pilot',
    },
    {
        tagName: 'META',
        attributes: { property: 'og:locale', content: 'en_US' },
    },
    {
        tagName: 'META',
        attributes: { property: 'og:type', content: 'article' },
    },
    {
        tagName: 'META',
        attributes: {
            property: 'og:title',
            content: 'Auto Insurance Archives - Policy Pilot',
        },
    },
    {
        tagName: 'META',
        attributes: {
            property: 'og:description',
            content: 'Learn about Auto Insurance',
        },
    },
    {
        tagName: 'META',
        attributes: {
            property: 'og:url',
            content:
                'https://dev-policypilot.pantheonsite.io/./learning-center/auto-insurance/',
        },
    },
    {
        tagName: 'META',
        attributes: { property: 'og:site_name', content: 'Policy Pilot' },
    },
    {
        tagName: 'META',
        attributes: { name: 'twitter:card', content: 'summary' },
    },
    {
        tagName: 'SCRIPT',
        attributes: {
            type: 'application/ld+json',
            class: 'yoast-schema-graph',
        },
        textContent:
            '{"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://dev-policypilot.pantheonsite.io/#website","url":"https://dev-policypilot.pantheonsite.io/","name":"Policy Pilot","description":"","potentialAction":[{"@type":"SearchAction","target":"https://dev-policypilot.pantheonsite.io/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"CollectionPage","@id":"https://dev-policypilot.pantheonsite.io/./learning-center/auto-insurance/#webpage","url":"https://dev-policypilot.pantheonsite.io/./learning-center/auto-insurance/","name":"Auto Insurance Archives - Policy Pilot","isPartOf":{"@id":"https://dev-policypilot.pantheonsite.io/#website"},"breadcrumb":{"@id":"https://dev-policypilot.pantheonsite.io/./learning-center/auto-insurance/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://dev-policypilot.pantheonsite.io/./learning-center/auto-insurance/"]}]},{"@type":"BreadcrumbList","@id":"https://dev-policypilot.pantheonsite.io/./learning-center/auto-insurance/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"item":{"@type":"WebPage","@id":"https://dev-policypilot.pantheonsite.io/","url":"https://dev-policypilot.pantheonsite.io/","name":"Home"}},{"@type":"ListItem","position":2,"item":{"@type":"WebPage","@id":"https://dev-policypilot.pantheonsite.io/./learning-center/","url":"https://dev-policypilot.pantheonsite.io/./learning-center/","name":"Learning Center"}},{"@type":"ListItem","position":3,"item":{"@id":"https://dev-policypilot.pantheonsite.io/./learning-center/auto-insurance/#webpage"}}]}]}',
    },
    {
        tagName: 'LINK',
        attributes: {
            rel: 'canonical',
            href:
                'https://www.lowermybills.com/mortgage/what-is-apr-how-it-affects-mortgages/',
        },
    },
    {
        tagName: 'META',
        attributes: {
            name: 'robots',
            content:
                'index,follow,max-snippet:-1,max-image-preview:large,max-video-preview:-1',
        },
    },
];
