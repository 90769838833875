import urlIsLocal from "../urlIsLocal";
import pathIsReleased from "../pathIsReleased";

const renderLink = ({ href, features }) => 
{
    if (!urlIsLocal(href)) return false;
    if (!pathIsReleased({ href, features })) return false;
    return true;
};
export default renderLink;
