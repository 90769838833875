import getURL from "../getURL";

const pathIsReleased = ({ href, features }) => 
{
    // if no feature strings of format "/pathname/", then all paths are released
    if (!features.some((feature) => /^\/.+\/$/.test(feature))) return true;

    const urlOb = getURL(href);
    if (!urlOb) return false;
    let { pathname } = urlOb;
    if (pathname[0] === "/") pathname = pathname.substring(1);
    if (features.includes(`/${pathname.split("/")[0]}/`)) return true;
    return false;
};
export default pathIsReleased;
