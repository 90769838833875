import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import FormError from '../FormError/index';

import { testAttributes } from './Constants';
import * as CSS from './Dropdown.module.scss';

const  DropDown = ({
    validate, errors, name, placeholder, data, label, tipText, action, controller: Controller, control
}) => 
    (
        <div data-test={testAttributes.MORTGAGE_DROPDOWN_CONTAINER} className={CSS.dropdownContainer}>
            <label htmlFor={name}>{label}</label>
            <span>{tipText}</span>
            <Controller
                name={name}
                control={control}
                rules={validate}
                render={({ field: { onChange, value, name: iName } }) => (
                    <select
                        name={iName}
                        onChange={(e) => 
                        {
                            onChange(e.target.value);
                            action.handleInputValidation(e);
                        }}
                        onFocus={(e) => action.handleOnfocus(e)}
                        value={value}
                        className={errors[name] ? `${CSS.select} ${CSS.dropdownFormError}` : CSS.select} 
                        data-test={`${testAttributes.MORTGAGE_DROPDOWN}${iName}`}
                    >
                        <option value="">{placeholder}</option>
                        {data.map((option) => (
                            <option key={`option-${option.value}`} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                )}
            />

            {errors[name] && <><div className={CSS.iconContainer}>
                <Icon data-test={testAttributes.MORTGAGE_DROPDOWN_ERROR_ICON} className={CSS.icon} icon="carbon:warning" />
            </div>
            <FormError className={CSS.dropdownTextError} errorMessage={errors[name].message} /></>}
        </div>
    )

DropDown.propTypes = {
    /** Object with validate rules */
    validate: PropTypes.objectOf(PropTypes.any), 
    /** object with validation errors */
    errors: PropTypes.objectOf(PropTypes.object),
    /** Dropdown name */  
    name: PropTypes.string.isRequired,
    /** Dropdown placeholder or label */
    placeholder: PropTypes.string.isRequired,
    /** Dropdown type (text, number) default text */
    data: PropTypes.arrayOf(PropTypes.object), 
    /** Input label */ 
    label: PropTypes.string,
    /** Input tip text */ 
    tipText: PropTypes.string,
    /** Input actions, object of functions */
    action: PropTypes.objectOf(PropTypes.any),
    /** contains methods for registering components */
    control: PropTypes.objectOf(PropTypes.any),
    /** Wrapper component for controlled inputs */
    controller: PropTypes.func.isRequired,
};

DropDown.defaultProps = {
    validate: {},
    errors: {},
    data: [],
    label: '',
    tipText: '',
    action: {},
    control: {}
};

export default DropDown;
