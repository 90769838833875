import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactCSSTransitionReplace from 'react-css-transition-replace';

import FormGenerator from '../../mortgage/FormGenerator';
import StepIndicator from '../../mortgage/StepIndicator';
import handleSmooth from '../../../utilities/handleSmooth';
import ModalAlert from '../../mortgage/ModalAlert';

import { testAttributes, mortgageToolResultContainer, defaultAttributes } from './Constants';

import * as CSS from './MortgageContainer.module.scss';
import "../../../utilities/transitionFormCSS/transitionForm.css"

export const scrollTo = () => 
{
    handleSmooth(
        `#${mortgageToolResultContainer} button`,
        `#${mortgageToolResultContainer}`
    );
};

const isInputValueValid = (properties, trigger) => 
{
    const inputNameArray = properties.filter(item => item.type !== 'button').map(item => item.name)
    return trigger (inputNameArray);
}

const FormContainer = ({ dataForm, resultsFields }) => 
{
    const [modal, setModal] = useState(false);
    const [formStep, setFormStep] = useState(0);
    const [formStepBack, setFormStepBack] = useState(0);
    const [result, setResult] = useState(false);
    const { formScheme, showSteps } = dataForm;

    const {
        register,
        handleSubmit,
        watch,
        trigger,
        formState: { errors },
        control,
        resetField,
    } = useForm({
        mode: 'onSubmit',
        shouldFocusError: false,
    });
    
    const handleGoBackToPreviousStep = async () => 
    {
        setFormStepBack(formStep);
        setFormStep(formStep - 1);
    };

    const onSubmit = async (values) => 
    {
        // eslint-disable-next-line no-unused-vars
        const {filter} = dataForm.formScheme[formStep];

        if (formStep < dataForm.formScheme.length -1) 
        {
            setFormStep(formStep + 1);
        }
        else
        {
            try 
            {
                const response = await dataForm.callBack(dataForm, values, resultsFields);
                setResult(response);
                scrollTo();
            }
            catch (error)
            {
                setModal(true);
            }
            
        }
    };

    const validateFormStep = () =>
    {
        const { properties } = formScheme[formStep];

        const inputNameArray = properties.filter(item => item.type !== 'button').map(item => item);
        const isEmpty = inputNameArray.every(item => 
        {
            const watched = watch(item.name);
            
            return watched?.length > (item?.validate?.maxLength - 1 || 0);
        });

        return !isEmpty;
    }

    const handleNextStep = async () =>
    {   
        const { properties } = formScheme[formStep];
        const validated = await isInputValueValid(properties, trigger);

        if (validated)
        {
            setFormStep(formStep + 1);
        }
    }

    const handleInputValidation = async (e) => 
    {
        await trigger(e.target.name);
    }

    const handleOnfocus = async (e) => 
    {
        resetField(e.target.name, {
            keepErrors: false, 
            keepDirty: false,
            defaultValue: e.target.value,
        });
    }

    return (
        <>
            <ReactCSSTransitionReplace
                transitionName= {formStepBack > formStep ? "form" : "prev"}
                component="div"
                childComponent="div"
                transitionEnterTimeout={1000}
                transitionLeaveTimeout={1000}
            >
                <div className={CSS.appFormRoot} key={`transitioner_${formStep || 0}`} data-test={testAttributes.MORTGAGE_CONTAINER}>
                    {dataForm.title && <h2>{dataForm.title}</h2>}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {formScheme.map(
                            (item, index) =>
                                index === formStep && (
                                    <FormGenerator
                                        key={`form-${item.step}`}
                                        {...item}
                                        register={register}
                                        watch={watch}
                                        controller={Controller}
                                        errors={errors}
                                        control={control}
                                        action={{
                                            handleGoBackToPreviousStep,
                                            handleNextStep,
                                            handleInputValidation,
                                            handleOnfocus
                                        }}
                                        step={formStep}
                                        btnDisabled={validateFormStep()}
                                    />
                                )
                        )}
                    </form>
                </div>
            </ReactCSSTransitionReplace>
            <div className={CSS.stepIndicatorContainer}>
                {showSteps && (
                    <StepIndicator
                        current={formStep + 1}
                        setState={setFormStep}
                        data={formScheme}
                    />
                )}
            </div>
            {
                result ? <div id={mortgageToolResultContainer} className={CSS.mortgageResults}>
                    {result}
                </div> : (<p className={CSS.resultLabel} data-test={testAttributes.MORTGAGE_HERE_RESULT_TEXT}>
                    {defaultAttributes.RESULT_CONTAINER}
                </p>)
            }
            {modal && <ModalAlert onClick={() => setModal(false)} />}
        </>
    );
}

FormContainer.propTypes = {
    /** Json SchemeForm to render  */
    dataForm: PropTypes.objectOf(PropTypes.any).isRequired,
    /** cell content from WP Editor for results table (e.g. "See More") */
    resultsFields: PropTypes.objectOf(PropTypes.any)
};

FormContainer.defaultProps = {
    resultsFields: {},
};

export default FormContainer;
