import { socialLinks } from '../../Constants';

const parseLayoutProps = (data, location) => 
{
    const footerProps = {
        itemsMenu: {
            policyMenu: data.footerMenuPolicy?.menuItems?.nodes || [],
            companyMenu: data.footerMenuCol1?.menuItems?.nodes || [],
            personalMenu: data.footerMenuCol2?.menuItems?.nodes || [],
        },
        socialLinks,
    };
    const headerProps = {
        headerMenu: data.headerMenuNav?.nodes.map((item) => 
        {
            const parsedItem = { ...item };
            parsedItem.childItems = item.submenu?.nodes;
            return parsedItem;
        }),
        logoUrl: data.site.siteMetadata.siteUrl,
        LogoAlt: `${data.site.siteMetadata.title} Logo`,
        locationPath: location?.pathname,
    };
    return { footerProps, headerProps };
};
export default parseLayoutProps;
