/* istanbul ignore file */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

const SampleEmbed = (props) => 
{
    useEffect(() => 
    {
        const script = document.createElement('script');
        script.text = `var embedSampleEl = document.getElementById('mainContent');
        embedSampleEl.appendChild( document.createTextNode("arg1: ${props['data-arg1']}"));
        embedSampleEl.appendChild( document.createElement('br'));
        embedSampleEl.appendChild( document.createTextNode("arg2: ${props['data-arg2']}"));
        embedSampleEl.appendChild( document.createElement('br'));
        embedSampleEl.appendChild( document.createTextNode("arg3: ${props['data-arg3']}"));
        
        
        
        console.log("arg1: ${props['data-arg1']}")
        console.log("arg2: ${props['data-arg2']}")
        console.log("arg3: ${props['data-arg3']}")
        `;
        document.body.appendChild(script);
        return () => 
        {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <h1>Sample Embed</h1>
            <div id="mainContent" />
        </div>
    );
};
export default SampleEmbed;
