import React from "react";
import PropTypes from 'prop-types';

import BlockButton from '../../simple/BlockButton';
import { modalAlertContainer, modalAlertContent, modalAlertHeader, modalAlertFooter, modalAlertBody }  from "./ModalAlert.module.scss";
import { testAttributes, defaultAttributes } from './Constants';
import IconAlert from '../../../static_assets/icons/oops.svg';

const ModalAlert = ({ message, labelBtn, onClick }) => 
    (
        <div className={modalAlertContainer} data-test={testAttributes.MODAL_ALERT_CONTAINER}>
            <div className={modalAlertContent}>
                <div className={modalAlertHeader}>
                    <h2>Something went wrong...</h2>
                </div>
                <div className={modalAlertBody}>
                    <img src={IconAlert} alt="alert" />
                    <p>{message}</p>
                </div>
                <div className={modalAlertFooter}>
                    <BlockButton
                        label={labelBtn}
                        submit={false}
                        onClick={onClick}
                    />
                </div>
            </div>
        </div>
    );

ModalAlert.propTypes = {
    message: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    labelBtn: PropTypes.string,
}

ModalAlert.defaultProps = {
    labelBtn: defaultAttributes.LABEL_BTN,
    message: defaultAttributes.CONTENT_MESSAGE,
}

export default ModalAlert;
