import allUponseExceptions from './uponSanitizeElement';
import mergeExceptions from '../mergeExceptions';
import addHookafterSanitizeAttributes from './afterSanitizeAttributes';

const hookExceptions = [allUponseExceptions];

// eslint-disable-next-line import/no-mutable-exports
let allHookExceptions = {};

addHookafterSanitizeAttributes();

hookExceptions.forEach((hookException) => 
{
    allHookExceptions = mergeExceptions(hookException, allHookExceptions);
});

export default allHookExceptions;
