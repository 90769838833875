// Spike ticket, some functions like 'scrollIntoView' are not working well with .jestfn()
const HandleSmooth = (clickTo, targetTo) => 
{
    if (typeof document !== `undefined`) 
    {
        const elClick = document.querySelector(clickTo);
        const elTarget = document.querySelector(targetTo);
        elTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });

        if (elClick) 
        {
            elTarget.click();
        }
        return { clickTo, targetTo };
    }
    return null;
};
export default HandleSmooth;
