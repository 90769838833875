/**
 * usage:
 * default: 
   const parsed = parseHTML( sampleHTML );
 * 
 * with custom replacement fn:
    const myParser = (sanitized, options) => {
        const defaultParser = HTMLReactParser.default;
        return defaultParser(sanitized, {
            replace: options.addReplacements([ customReplacer1, customReplacer2, ...]),
        });
    };
    const parsed = parseHTML(sampleHTML, myParser);
 */
import parserReplacements from './replacements';

const doReplacements = (addedReplacements = []) => 
{
    let mergedReplacements = parserReplacements;
    // for some unknown reason, jest throws undefined error for imported parserReplacements. This fix:
    if (undefined === mergedReplacements) mergedReplacements = [];
    if (Array.isArray(addedReplacements)) 
    {
        mergedReplacements = [...addedReplacements, ...mergedReplacements];
    }
    return (domNode) => 
    {
        for (let ix = 0; ix < mergedReplacements.length; ix += 1) 
        {
            const converted = mergedReplacements[ix](domNode);
            if (converted) return converted;
        }
        return null;
    };
};
const options = {
    replace: doReplacements(),
    addReplacements: doReplacements,
};

export default options;
